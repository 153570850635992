import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import marked from 'marked'

export default class BlogPreview extends PureComponent {
  state = {
    text: ''
  }
  componentDidMount = async () => {
    const text = await this.cleanPost(this.props.post)
    this.setState({
      text
    })
  }
  cleanPost = (content) => {
    let cleaned = content.replace(/<iframe.*?<\/iframe>/g, '')
    return cleaned
  }
  render() {
    return (
      <div className = 'blog-post'>
        <NavLink to = {`/blog/${this.props.slug}`} className = 'blog-nav'>
          <h2 className = 'color-black'>{this.props.title}</h2>
          <img
            src = {this.props.featureImage}
            alt = 'Daily Product Prep'
          />
        </NavLink>
        <HTMLEllipsis
          className = 'blog-post-preview'
          unsafeHTML = {marked(this.state.text)}
          maxLine = {5}
          ellipsis = '...'
        />
        <NavLink to = {`/blog/${this.props.slug}`} className = 'read-more'>
          <p>... read more</p>
        </NavLink>
      </div>
    )
  }
}
