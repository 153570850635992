import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import { humanizePrice } from '@/lib/humanize'

import FreePlan from '@/assets/pricing/freePlan.svg'
import MonthlyPlan from '@/assets/pricing/monthlyPlan.svg'
import YearlyPlan from '@/assets/pricing/yearlyPlan.svg'
import Checkmark from '@/assets/iconography/checkmark.svg'
import './styles.scss'

export default class PricingPlans extends PureComponent {
  render() {
    return (
      <div className = 'column'>
        <div className = 'pricing-subheader' id = 'plans'>
          <span>Plans</span>
        </div>
        <div className = 'pricing-plans'>
          <div className = 'pricing-plan'>
            <div className = 'plan-upper'>
              <img
                src = {FreePlan}
                alt = 'Daily Product Prep free product manager questions'
                className = 'plan-splash'
              />
              <div>
                <span className = 'plan-name'>Free</span>
                <div className = 'plan-price-list'>
                  <span className = 'plan-price'>$0</span>
                </div>
              </div>
            </div>
            {
              this.props.user && this.props.user.type === 'free' ? (
                <button className = 'current-plan-button' disabled>Current plan</button>
              ) : !this.props.user ? (
                <NavLink to = {`/signup`}>
                  <button className = 'subtle-button'>Get started</button>
                </NavLink>
              ) : (
                <NavLink to = {`/cancel`}>
                  <button className = 'subtle-button'>Downgrade</button>
                </NavLink>
              )
            }
            <div className = 'plan-description'>
              For improving at product sense and product design interview questions.
            </div>
            <div className = 'plan-list'>
              <p className = 'plan-list-title'>Free forever</p>
              <ul>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  Start with 5 problems
                </li>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  1 new problem per day
                </li>
              </ul>
            </div>
          </div>
          <div className = 'pricing-plan'>
            <div className = 'plan-upper'>
              <img
                src = {MonthlyPlan}
                alt = 'Daily Product Prep monthly product manager questions'
                className = 'plan-splash'
              />
              <div>
                <span className = 'plan-name'>Pro Monthly</span>
                <div className = 'plan-price-list'>
                  <span className = 'plan-price'>{this.props.monthlyPrice < 15 ? <span className = 'strikethrough small color-primary'>$15</span> : null} ${humanizePrice(this.props.monthlyPrice)}</span>
                  <div className = 'plan-price-units'>
                    <span className = 'plan-price-unit'>per month</span>
                  </div>
                </div>
              </div>
            </div>
            {
              this.props.user && this.props.user.plan === 'monthly' && this.props.user.type === 'premium' ? (
                <button className = 'current-plan-button' disabled>Current plan</button>
              ) : this.props.user && this.props.user.plan === 'yearly' && this.props.user.type === 'premium' ? (
                <button className = 'subtle-button' onClick = {() =>  this.props.updateSubscription('monthly')}>Select</button>
              ) :  this.props.user ? (
                <NavLink to = {`/secure/checkout?plan=monthly${this.props.coupon ? `&code=${this.props.coupon.code}` : ``}`}>
                  <button className = 'subtle-button'>Upgrade</button>
                </NavLink>
              ) : (
                <NavLink to = {`/secure/checkout?plan=monthly${this.props.coupon ? `&code=${this.props.coupon.code}` : ``}`}>
                  <button className = 'subtle-button'>Get started</button>
                </NavLink>
              )
            }
            <div className = 'plan-description'>
              For those looking to give impeccable interview answers every time.
            </div>
            <div className = 'plan-list'>
              <p className = 'plan-list-title'>Everything in Free, plus</p>
              <ul>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  Start with <span className = 'strikethrough'>5</span> <strong>20</strong> problems
                </li>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  View expert and community <strong>solutions</strong>
                </li>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  Community <strong>feedback</strong> on your solutions
                </li>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  Job referrals
                  <div className = 'pricing-coming-soon'>Coming soon</div>
                </li>
              </ul>
            </div>
          </div>
          <div className = 'pricing-plan'>
            <div className = 'plan-upper'>
              <img
                src = {YearlyPlan}
                alt = 'Daily Product Prep yearly product manager questions'
                className = 'plan-splash'
              />
              <div>
                <span className = 'plan-name'>Pro Yearly</span>
                <div className = 'plan-price-list'>
                  <span className = 'plan-price'><span className = 'strikethrough small color-primary'>{humanizePrice(this.props.yearlyPrice / 12 / 100) === `10` ? `$15` : `$10`}</span> ${humanizePrice(this.props.yearlyPrice / 12 / 100)}</span>
                  <div className = 'plan-price-units'>
                    <span className = 'plan-price-unit'>per month</span>
                    <span className = 'plan-price-unit'>billed annually</span>
                  </div>
                </div>
              </div>
            </div>
            {
              this.props.user && this.props.user.plan === 'yearly' && this.props.user.type === 'premium' ? (
                <button className = 'current-plan-button' disabled>Current plan</button>
              ) : this.props.user && this.props.user.plan === 'monthly' && this.props.user.type === 'premium' ? (
                <button onClick = {() => this.props.updateSubscription('yearly')}>Upgrade</button>
              ) : this.props.user ? (
                <NavLink to = {`/secure/checkout?plan=yearly${this.props.coupon ? `&code=${this.props.coupon.code}` : ``}`}>
                  <button>Upgrade</button>
                </NavLink>
              ) : (
                <NavLink to = {`/secure/checkout?plan=yearly${this.props.coupon ? `&code=${this.props.coupon.code}` : ``}`}>
                  <button>Get started</button>
                </NavLink>
              )
            }
            <div className = 'plan-description'>
              For those looking to truly stand out as a candidate.
            </div>
            <div className = 'plan-list'>
              <p className = 'plan-list-title'>Everything in Pro Monthly, plus</p>
              <ul>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  Start with <span className = 'strikethrough'>20</span> <strong>60</strong> problems
                </li>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  Save $5 per month
                </li>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  Exclusive affiliate perks
                </li>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  Mock interview access
                </li>
                <li>
                  <img
                    src = {Checkmark}
                    alt = 'Daily Product Prep product manager checkmark'
                    className = 'pricing-checkmark'
                  />
                  Advanced permissions
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}