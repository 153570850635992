import React, { PureComponent } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import marked from 'marked'

import ChevronLeft from '@/assets/iconography/chevronLeft.svg'
import ChevronRight from '@/assets/iconography/chevronRight.svg'
import './styles.scss'

class SampleProblem extends PureComponent {
	state = {
		page: 1,
		email: null,
	}
	componentDidMount = () => {
		this.setProblem(1)
	}
	createMarkup = (content) => {
    const html = marked(content)
    return { __html: html }
  }
  changePage = (direction) => {
  	let page = this.state.page
  	if(direction === 'left') {
  		if(page === 1) {
  			page = 3
  		} else if (page === 2) {
  			page = 1
  		} else if (page === 3) {
  			page = 2
  		}
  	} else {
  		if(page === 1) {
  			page = 2
  		} else if (page === 2) {
  			page = 3
  		} else if (page === 3) {
  			page = 1
  		}
  	}
  	this.setProblem(page)
  	this.setState({
  		page
  	})
  }
  setProblem = (page) => {
  	this.setState({
  		problem: this.props.problems[page - 1]
  	})
  }
	render() {
		if(!this.props.problems || !this.state.problem) {
			return null
		}
		return (
			<div className = 'sample-problem'>
				<div className = 'sample-problem-container'>
					<div className = 'sample-problem-header'>
						<h2 className = 'sample-problem-header-text'>Can you solve these interview questions?</h2>
						<p className = 'sample-problem-subheader-text'>A good candidate will answer these succinctly and confidently with a framework.</p>
					</div>
					<div className = 'sample-problem-sample'>
						<div className = 'sample-tile'>
							<h3 className = 'sample-title'>{this.state.page}. {this.state.problem.title}</h3>
							<div className = 'pills'>
								{
									this.state.problem.companies.map(company => {
										return <p className = 'company'>Asked by {company}</p>
									})
								}
								{
									this.state.problem.topics.map(topic => {
										return <p className = 'topic'>{topic} question</p>
									})
								}
							</div>
							<div className = 'sample-markup' dangerouslySetInnerHTML = {this.createMarkup(this.state.problem.problem)} />
							<div className = 'error-text'>{this.props.error}</div>
							<div className = 'sample-cta'>
								<input
									type = 'email'
									placeholder = 'Sign up to unlock...'
									value = {this.state.email}
									onChange = {(ev) => this.setState({ email: ev.target.value })}
									className = 'sample-input'
								/>
								<NavLink to = {{pathname: `/signup`, email: this.state.email}}>
									<button className = 'sample-button'>Unlock solutions</button>
								</NavLink>
							</div>
						</div>
					</div>
					<div className = 'sample-problem-switcher'>
						<div onClick = {() => this.changePage('left')} className = 'switcher'>
							<img
								src = {ChevronLeft}
								alt = 'Daily Product Prep'
								className = 'switcher-chevron'
							/>
						</div>
						<span>{this.state.page} / 3</span>
						<div onClick = {() => this.changePage('right')} className = 'switcher'>
							<img
								src = {ChevronRight}
								alt = 'Daily Product Prep'
								className = 'switcher-chevron'
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(SampleProblem)