import React, { PureComponent } from 'react'

import './styles.scss'

export default class ProblemLoaderCard extends PureComponent {
	render() {
		if(this.props.borderless) {
			return (
				<div className = 'loader-card borderless'>
					<div className = 'left-container'>
						<div className = 'card-details'>
							<div className = 'bar' />
							<div className = 'pills'>
								<div className = 'pill-bar' />
								<div className = 'pill-bar' />
								<div className = 'pill-bar' />
							</div>
							<div className = 'optional-tags'>
								<div className = 'optional-bar' />
								<div className = 'optional-bar' />
							</div>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div className = 'loader-card'>
				<div className = 'left-container'>
					<div className = 'card-details'>
						<div className = 'bar' />
						<div className = 'pills'>
							<div className = 'pill-bar' />
							<div className = 'pill-bar' />
							<div className = 'pill-bar' />
						</div>
						<div className = 'optional-tags'>
							<div className = 'optional-bar' />
							<div className = 'optional-bar' />
						</div>
					</div>
				</div>
			</div>
		)
	}
}