import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

import { STORAGE_KEY } from '@/lib/constants'

import Daily from '@/assets/onboarding/daily.svg'
import Weekly from '@/assets/onboarding/weekly.svg'
import Selected from '@/assets/iconography/selected.svg'
import Unselected from '@/assets/iconography/unselected.svg'

class Onboarding03EmailPreferences extends PureComponent {
  state = {
    error: null,
    problemFrequency: null,
    continueEnabled: false,
  }
  componentDidMount = async () => {
    if(this.props.user && this.props.user.emailPreferences && this.props.user.emailPreferences.problemFrequency) {
      this.setState({
        problemFrequency: this.props.user.emailPreferences.problemFrequency,
        continueEnabled: true,
      })
    }
  }
  setProblemFrequency = (problemFrequency) => {
    this.setState({
      problemFrequency: problemFrequency,
      continueEnabled: true,
    })
  }
  continue = async () => {
    this.setState({
      error: null
    })
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/users/onboarding/email-preferences`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        problemFrequency: this.state.problemFrequency
      })
    })
    const { auth, error } = await res.json()
    if (auth) {
      this.props.history.push('/setup/complete')
    } else {
      this.setState({
        error
      })
    }
  }
  render() {
    return (
      <div className = 'column align-items-center'>
        <div className = 'column text-align-center margin-bottom'>
          <h1 className = 'no-margin'>Lastly - how often should we send you interview problems?</h1>
          <h3 className = 'thin'>You'll still receive the same amount of content no matter which option you choose.</h3>
        </div>
        <div className = 'column align-items-center'>
          <div className = 'onboarding-selector-container'>
            <div className = {this.state.problemFrequency === 'daily' ? 'onboarding-selector-card' : 'onboarding-selector-card unselected'} onClick = {() => this.setProblemFrequency(`daily`)}>
              <img
                src = {this.state.problemFrequency === 'daily' ? Selected : Unselected}
                alt = 'Daily Product Prep selector'
                className = 'selector'
              />
              <div className = 'text'>
                <img
                  src = {Daily}
                  alt = 'Daily Product Prep daily product management emails'
                  className = 'onboarding-card-splash'
                />
                <p className = 'title'>Daily</p>
                <p className = 'subtitle'>5-6 emails per week, including one interview question sent each weekday.</p>
              </div>
            </div>
            <div className = {this.state.problemFrequency === 'weekly' ? 'onboarding-selector-card' : 'onboarding-selector-card unselected'} onClick = {() => this.setProblemFrequency(`weekly`)}>
              <img
                src = {this.state.problemFrequency === 'weekly' ? Selected : Unselected}
                alt = 'Daily Product Prep selector'
                className = 'selector'
              />
              <div className = 'text'>
                <img
                  src = {Weekly}
                  alt = 'Daily Product Prep weekly product management emails'
                  className = 'onboarding-card-splash'
                />
                <p className = 'title'>Weekly</p>
                <p className = 'subtitle'>1-2 emails per week, combining all the week's content into one or two emails.</p>
              </div>
            </div>
          </div>
          <div className = 'onboarding-continue focusable column'>
            <span className = 'error-text'>{this.state.error}</span>
            {
              this.state.continueEnabled ?
              <button onClick = {() => this.continue()}>Continue</button>
              : <button className = 'disabled-button' disabled>Continue</button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Onboarding03EmailPreferences)