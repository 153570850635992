import React, { PureComponent } from 'react'
import { Elements } from 'react-stripe-elements'

import InjectedCheckout from '@/components/InjectedCheckout'

export default class ElementsCheckout extends PureComponent {
  render() {
    return (
      <Elements>
        <InjectedCheckout
        	user = {this.props.user}
        	error = {this.props.error}
          setEmail = {this.props.setEmail}
          emailWarning = {this.props.emailWarning}
          onCheckout = {this.props.onCheckout}
        />
      </Elements>
    )
  }
}
