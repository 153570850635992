import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

import { humanizeNumber } from '@/lib/humanize'

import PraiseClear from '@/assets/iconography/praiseClear.svg'
import PraiseDark from '@/assets/iconography/praiseDark.svg'
import ChevronRight from '@/assets/iconography/chevronRight.svg'
import Solution from '@/assets/iconography/solution.svg'
import Comment from '@/assets/iconography/comment.svg'
import Checkmark from '@/assets/iconography/checkmark.svg'
import Share from '@/assets/iconography/share.svg'

import './styles.scss'

export default class ProblemCard extends PureComponent {
	state = {
		userPraiseCount: 0,
		problemPraiseCount: 0,
		showCopied: false,
	}
	componentDidMount = () => {
		this.setState({
			userPraiseCount: this.props.problem ? this.props.problem.numPraises : 0,
			problemPraiseCount: this.props.problem ? this.props.problem.praised : 0,
		})
	}
	clickShare = (ev, problem) => {
		this.setState({
			showCopied: true
		})
		ev.stopPropagation()
		navigator.clipboard.writeText(`https://dailyproductprep.com/problems/${this.props.problem.slug}?utm_medium=referral&utm_source=share&utm_campaign=shareproblem`)
		setTimeout(() => {
			this.setState({
				showCopied: false
			})
		}, 2000)
	}
	praiseProblem = (ev, item) => {
		ev.preventDefault()
		this.props.praiseProblem(item)
		if(this.state.userPraiseCount < 10) {
			this.setState({
				userPraiseCount: parseInt(this.state.userPraiseCount) + 1,
				problemPraiseCount: parseInt(this.state.problemPraiseCount) + 1,
			})
		}
	}
	render() {
		if(this.props.location === 'problems') {
			return (
				<NavLink to = {this.props.route} className = 'card'>
					<div className = 'left-container'>
						<div className = 'votes-container'>
							<span className = {this.state.problemPraiseCount > 0 ? 'color-primary bold xsmall' : 'bold xsmall'}>{this.state.userPraiseCount}</span>
							<div onClick = {(ev) => this.praiseProblem(ev, this.props.problem)}>
								{
									this.state.problemPraiseCount >= 10 ? (
										<img
											src = {PraiseClear}
											alt = 'Daily Product Prep'
											className = 'praise'
										/>
									) : (
										<img
											src = {PraiseDark}
											alt = 'Daily Product Prep'
											className = 'praise'
										/>
									)
								}
							</div>
						</div>
						<div className = 'card-details'>
							<div className = 'title'>
								<h3 className = 'problem-title'>{this.props.problem.title}</h3>
							</div>
							<div className = 'details'>
								<div className = 'pills'>
									{
										this.props.problem.problemNumber <= 5 ? (
											<p className = 'pill free'>Free</p>
										) : null
									}
									{
										this.props.problem.companies.map(company => {
											return <p className = 'pill company'>{company}</p>
										})
									}
									{
										this.props.problem.topics.map(topic => {
											return <p className = 'pill topic'>{topic}</p>
										})
									}
								</div>
							</div>
							<div className = 'optional-tags'>
								<div className = 'solution-tag'>
									<img
										src = {Solution}
										alt = 'Daily Product Prep'
										className = 'solved'
									/>
									<span className = 'card-footer xsmall'>{humanizeNumber(this.props.problem.numSolutions)} {this.props.problem.numSolutions === `1` ? 'solution' : 'solutions'}</span>
									<img
										src = {Comment}
										alt = 'Daily Product Prep'
										className = 'solved'
									/>
									<span className = 'card-footer xsmall'>{humanizeNumber(this.props.problem.numComments)} {this.props.problem.numComments === `1` ? 'comment' : 'comments'}</span>
								</div>
							</div>
						</div>
					</div>
					<div className = 'right-container'>
						<div className = 'right-chevron-container'>
							<img
								src = {ChevronRight}
								alt = 'Daily Product Prep'
								className = 'right-chevron'
							/>
						</div>
					</div>
				</NavLink>
			)
		} else if(this.props.location === 'problem') {
			return (
				<div className = 'card borderless'>
					<div className = 'left-container'>
						<div className = 'votes-container'>
							<span className = {this.state.problemPraiseCount > 0 ? 'color-primary bold xsmall' : 'bold xsmall'}>{this.state.userPraiseCount}</span>
							<div onClick = {(ev) => this.praiseProblem(ev, this.props.problem)}>
								{
									this.state.problemPraiseCount >= 10 ? (
										<img
											src = {PraiseClear}
											alt = 'Daily Product Prep'
											className = 'praise'
										/>
									) : (
										<img
											src = {PraiseDark}
											alt = 'Daily Product Prep'
											className = 'praise'
										/>
									)
								}
							</div>
						</div>
						<div className = 'card-details'>
							<div className = 'title'>
								<h3 className = 'problem-title'>{this.props.problem.title}</h3>
							</div>
							<div className = 'details'>
								<div className = 'pills'>
									{
										this.props.problem.problemNumber <= 5 ? (
											<p className = 'pill free'>Free</p>
										) : null
									}
									{
										this.props.problem.companies.map(company => {
											return <p className = 'pill company'>{company}</p>
										})
									}
									{
										this.props.problem.topics.map(topic => {
											return <p className = 'pill topic'>{topic}</p>
										})
									}
								</div>
							</div>
							<div className = 'optional-tags'>
								<div className = 'solution-tag'>
									<img
										src = {Solution}
										alt = 'Daily Product Prep'
										className = 'solved'
									/>
									<span className = 'card-footer xsmall'>{humanizeNumber(this.props.problem.numSolutions)} {this.props.problem.numSolutions === `1` ? 'solution' : 'solutions'}</span>
									<img
										src = {Comment}
										alt = 'Daily Product Prep'
										className = 'solved'
									/>
									<span className = 'card-footer xsmall'>{humanizeNumber(this.props.problem.numComments)} {this.props.problem.numComments === `1` ? 'comment' : 'comments'}</span>
									<div className = {this.state.showCopied ? 'share-btn-hide' : 'share-btn-show'} onClick = {(e) => this.clickShare(e, this.props.problem)}>
										<img
											src = {Share}
											alt = 'Daily Product Prep'
											className = 'solved'
										/>
										<span className = 'card-footer xsmall'>Share</span>
									</div>
									<div className = {this.state.showCopied ? 'copied-btn-show' : 'copied-btn-hide'}>
										<img
											src = {Checkmark}
											alt = 'Daily Product Prep'
											className = 'solved'
										/>
										<span className = 'card-footer xsmall'>Copied!</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		} else if(this.props.location === 'profile') {
			return (
				<NavLink to = {this.props.route} className = 'card'>
					<div className = 'left-container'>
						<div className = 'card-details'>
							<div className = 'title'>
								<h3 className = 'problem-title'>{this.props.solution.problem.title}</h3>
							</div>
							<div className = 'details'>
								<div className = 'pills'>
									{
										this.props.solution.problem.problemNumber <= 5 ? (
											<p className = 'pill free'>Free</p>
										) : null
									}
									{
										this.props.solution.problem.companies.map(company => {
											return <p className = 'pill company'>{company}</p>
										})
									}
									{
										this.props.solution.problem.topics.map(topic => {
											return <p className = 'pill topic'>{topic}</p>
										})
									}
								</div>
							</div>
							<div className = 'optional-tags'>
								<div className = 'solution-tag'>
									<span className = 'card-footer xsmall'>Solved {moment(this.props.solution.createdAt).fromNow()}</span>
								</div>
							</div>
						</div>
					</div>
					<div className = 'right-container'>
						<div className = 'right-chevron-container'>
							<img
								src = {ChevronRight}
								alt = 'Daily Product Prep'
								className = 'right-chevron'
							/>
						</div>
					</div>
				</NavLink>
			)
		}
		return null
	}
}