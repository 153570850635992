import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import cookieManager from '@/lib/cookieManager'
import { trackPageView, trackEvent } from '@/lib/analytics'

import AcceptTermsAndPrivacy from '@/components/AcceptTermsAndPrivacy'
import Loader from '@/components/Loader'

export default class SignUp extends PureComponent {
	state = {
		error: null,
		email: null,
		emailWarning: null,
		requesting: false,
		validTLDs: ['.com','.ca','.edu','.org','.co','.io']
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		if(this.props.user) {
			this.props.history.push('/')
		}
		if(this.props.location.email) {
			await this.setState({
				email: this.props.location.email
			})
			await this.subscribe()
		}
	}
	checkEmail = (email) => {
		let TLDs = this.state.validTLDs
		let showWarning = true
		for(let ending of TLDs) {
		  if(email.indexOf(ending) === email.length - ending.length) {
		    showWarning = false
		  }
		}
		this.setState({
			emailWarning: showWarning ? 'This email looks incorrect, double check before pressing sign up!' : null,
		})
	}
	subscribe = async () => {
    this.setState({
      error: null,
      requesting: true,
    })
    const visitorId = cookieManager.getVisitorId()
    const referrer = cookieManager.getReferrer()
    const cookies = {
      visitorId,
      referrer,
    }
    const params = {
      email: this.state.email,
      cookies,
    }
    const res = await fetch(`/api/users/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      },
      body: JSON.stringify(params)
    })
    const { auth, token, resubscribed, error } = await res.json()
    if (auth) {
      if(resubscribed) {
      	trackEvent({
      		category: 'Acquisition',
      		action: 'User resubscribed via signup',
      		label: referrer,
      		value: 0,
      		nonInteraction: false,
      	})
        this.setState({
          resubscribed,
          requesting: false,
        })
      } else {
      	trackEvent({
      		category: 'Acquisition',
      		action: 'Signed up for an account',
      		label: referrer,
      		value: 0,
      		nonInteraction: false,
      	})
        await this.props.login(token)
        await this.props.history.push('/')
      }
    } else {
      this.setState({
        error,
        requesting: false,
      })
    }
  }
	render() {
		if(this.state.resubscribed) {
			return (
				<div className = 'column align-items-center'>
					<Helmet>
            <title>Get Daily Product Interview Questions - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Get daily product management interview questions delivered daily to your inbox from top companies like Lyft, Apple, Microsoft, Alphabet, Tesla, Facebook, Instagram to practice for your next product management interview."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<div className = 'auth-title'>Sign up</div>
					<div className = 'column align-items-center'>
						<div className = 'margin-bottom'>
							<span className = 'text-align-center color-grey'>You've successfully resubscribed!</span>
						</div>
						<div className = 'margin-bottom focusable column'>
							<NavLink to = {`/login`}>
								<button>Log in...</button>
							</NavLink>
						</div>
					</div>
					<AcceptTermsAndPrivacy
						title = {`Sign up`}
					/>
				</div>
			)
		}
		if(this.state.requesting) {
			return (
				<div className = 'column align-items-center'>
					<Helmet>
            <title>Get Daily Product Interview Questions - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Get daily product management interview questions delivered daily to your inbox from top companies like Lyft, Apple, Microsoft, Alphabet, Tesla, Facebook, Instagram to practice for your next product management interview."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<div className = 'auth-title'>Sign up</div>
					<Loader />
					<AcceptTermsAndPrivacy
						title = {`Sign up`}
					/>
				</div>
			)
		}
		return (
			<div className = 'column align-items-center'>
				<Helmet>
          <title>Get Daily Product Interview Questions - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Get daily product management interview questions delivered daily to your inbox from top companies like Lyft, Apple, Microsoft, Alphabet, Tesla, Facebook, Instagram to practice for your next product management interview."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<div className = 'auth-title'>Sign up</div>
				<div className = 'column align-items-center'>
					<div className = 'margin-bottom focusable column'>
						<span className = 'input-title'>Email</span>
						<input
							placeholder = 'Enter your email address...'
							type = 'email'
							className = 'auth-input'
							value = {this.state.email}
							onChange = {(ev) => {this.setState({email:ev.target.value}); this.checkEmail(ev.target.value)}}
						/>
						<span className = 'warning-text'>{this.state.emailWarning}</span>
					</div>
					<div className = 'margin-bottom focusable column'>
						<span className = 'error-text'>{this.state.error}</span>
						<button onClick = {() => this.subscribe()}>Sign up</button>
					</div>
				</div>
				<AcceptTermsAndPrivacy
					title = {`Sign up`}
				/>
			</div>
		)
	}
}