import React, { PureComponent } from 'react'
import { NavHashLink as NavLink } from 'react-router-hash-link'

import Logo from '@/assets/logos/logo.svg'

import './styles.scss'

export default class Footer extends PureComponent {
  render() {
    return (
    	<div className = 'column'>
        <div className = 'footer'>
          <div className = 'footer-grid'>
            <div className = 'footer-grid-item'>
              <div className = 'footer-title-container'>
                <div className = 'footer-logo-container'>
                  <img
                    src = {Logo}
                    alt = 'Daily Product Prep'
                    className = 'footer-logo'
                  />
                </div>
                <div className = 'margin-left'>
                  <div className = 'footer-title'>Daily Product Prep</div>
                  <div className = 'footer-subtitle'>
                    <a href = {`https://www.tryexponent.com?utm_source=dpp&utm_medium=affiliate&utm_campaign=dpp-footer&ref=dpp`} className = 'footer-subtitle-link'>by Exponent</a>
                  </div>
                </div>
              </div>
              <div className = 'footer-info-section'>
                <div className = 'footer-info-item'>
                  <div className = 'footer-trademark'>
                    © Daily Product Prep 2021.
                  </div>
                  <div className = 'footer-rights'>
                    All rights reserved.
                  </div>
                </div>
              </div>
            </div>
            <div className = 'footer-grid-item'>
              <div className = 'footer-title-container'>
                <span className = 'footer-title'>About</span>
              </div>
              <div className = 'footer-info-section'>
                <div className = 'footer-info-item'>
                  <NavLink smooth to = {`/pricing#plans`} className = 'footer-link'>Pricing</NavLink>
                </div>
                <div className = 'footer-info-item mobile-hidden'>
                  <NavLink smooth to = {`/pricing#features`} className = 'footer-link'>Features</NavLink>
                </div>
                <div className = 'footer-info-item'>
                  <NavLink smooth to = {`/pricing#faq`} className = 'footer-link'>FAQ</NavLink>
                </div>
              </div>
            </div>
            <div className = 'footer-grid-item'>
              <div className = 'footer-title-container'>
                <span className = 'footer-title'>Terms</span>
              </div>
              <div className = 'footer-info-section'>
                <div className = 'footer-info-item'>
                  <NavLink smooth to = {`/terms-and-conditions#terms`} className = 'footer-link'>Terms and Conditions</NavLink>
                </div>
                <div className = 'footer-info-item'>
                  <NavLink smooth to = {`/privacy-policy#privacy`} className = 'footer-link'>Privacy Policy</NavLink>
                </div>
              </div>
            </div>
            <div className = 'footer-grid-item'>
              <div className = 'footer-title-container'>
                <span className = 'footer-title'>Contact</span>
              </div>
              <div className = 'footer-info-section'>
                <div className = 'footer-info-item'>
                  <a href = {'mailto:daily@dailyproductprep.com'} className = 'footer-link'>Contact Us</a>
                </div>
                <div className = 'footer-info-item'>
                  <a href = {'mailto:daily@dailyproductprep.com?subject=Support'} className = 'footer-link'>Support</a>
                </div>
                <div className = 'footer-info-item'>
                  <a href = {'mailto:daily@dailyproductprep.com?subject=Partnerships'} className = 'footer-link'>Partnerships</a>
                </div>
              </div>
            </div>
          </div>
        </div>
    	</div>
    )
  }
}
