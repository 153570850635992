import ReactGA from 'react-ga'

export function initializeAdTracking() {
  const GAKey = process.env.NODE_ENV === 'development' ? null : 'UA-149941531-1'
  ReactGA.initialize(GAKey)
}

export function trackPageView(path) {
  ReactGA.pageview(path)
}

export function trackEvent(params) {
  const { category, action, label, value, nonInteraction, transport } = params
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
    transport,
  })
}