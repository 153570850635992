import React, { Component } from 'react'
// import { NavLink } from 'react-router-dom'

import './styles.scss'

export default class Ribbon extends Component {
  render() {
    // Activate sale
//     if(this.props.coupon && this.props.coupon.code === 'blackfriday2022') {
//       return (
//         <div className = 'ribbon'>
//           <span><span role = 'img' aria-label = 'fire'>🚨</span> Cyber Monday Sale: Get 33% off when you upgrade to yearly! Last day to claim! <a href = 'https://dailyproductprep.com/pricing?ref=blackfriday2022' className = 'underline hover color-primary'>Upgrade now</a></span>
//         </div>
//       )
//     } else {
//       return (
//         <div className = 'ribbon'>
//           <span><span role = 'img' aria-label = 'fire'>🚨</span> Cyber Monday Sale: Get 33% off all plans now! Last day to claim! <a href = 'https://dailyproductprep.com/pricing?ref=blackfriday2022' className = 'underline hover color-primary'>Upgrade now</a></span>
//         </div>
//       )
//     }
//     if(this.props.coupon) {
//       return (
//         <div className = 'ribbon'>
//           <span>The discount code <b>{this.props.coupon.code}</b> was applied thanks to <b>{this.props.coupon.affiliate.name}</b>. <NavLink to = {`/pricing`} className = 'underline hover color-primary'>{this.props.user? `Upgrade now!` : `Get started!`}</NavLink></span>
//         </div>
//       )
//     } else {
      return (
        <div className = 'ribbon'>
          {/* eslint-disable-next-line */}
          <span>Get fully prepared for your PM interview with <b>Exponent</b>. <a href={`https://www.tryexponent.com/courses/pm?utm_source=dpp&utm_medium=affiliate&utm_campaign=dpp-banner&ref=dpp`} target = '_blank' className = 'underline hover color-primary'>Join&nbsp;Today&nbsp;→</a></span>
        </div>
      )
//     }
  }
}
