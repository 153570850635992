import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements'
import ReCAPTCHA from 'react-google-recaptcha'

const recaptchaRef = React.createRef()

class InjectedCheckout extends PureComponent {
  state = {
    email: null,
    attemptPayment: false,
    success: false,
  }
  onSubmit = (ev) => {
    ev.preventDefault()
    recaptchaRef.current.execute()
  }
  onChange = async () => {
    const recaptchaValue = recaptchaRef.current.getValue()
    this.setState({ attemptPayment: true })
    const token = await this.props.stripe.createToken({ type: 'card' })
    if (token) {
      await this.props.onCheckout(token, recaptchaValue)
    } 
    this.setState({ attemptPayment: false })
  }
  render() {
    const sitekey = '6Ld3B8gUAAAAADTXcA4lzsMdM6G6cc3blCacRrPO'
    return (
      <div>
        <form onSubmit = {this.onSubmit}>
          <ReCAPTCHA
            sitekey = {sitekey}
            size = 'invisible'
            ref = {recaptchaRef}
            onChange = {this.onChange}
          />
          <div className = 'column'>
            <div className = 'checkout-subheader'>
              <span>Account Information</span>
            </div>
            {
              this.props.user ? (
                <div className = 'checkout-entry-section'>
                  <span className = 'color-primary large margin-top'>{this.props.user.email}</span>
                  <span className = 'color-grey margin-top xsmall'>Not you? <NavLink to = {`/logout`} className = 'color-grey hover underline'>Logout</NavLink></span>
                </div>
              ) : (
                <div className = 'checkout-entry-section'>
                  <div className = 'margin-bottom focusable column'>
                    <span className = 'input-title'>Email</span>
                    <input
                      placeholder = 'Enter your email...'
                      type = 'email'
                      className = 'checkout-input'
                      onChange = {(ev) => this.props.setEmail(ev.target.value)}
                    />
                    <span className = 'warning-text'>{this.props.emailWarning}</span>
                  </div>
                </div>
              )
            }
          </div>
          <div className = 'column'>
            <div className = 'checkout-subheader'>
              <span>Payment details</span>
            </div>
            <div className = 'checkout-entry-section'>
              <div className = 'margin-bottom column'>
                <span className = 'input-title'>Card information</span>
                <fieldset className = 'payment-fieldset'>
                  <div>
                    <div className = 'payment-number-input'>
                      <CardNumberElement />
                    </div>
                    <div className = 'payment-additional-input'>
                      <div className = 'card-expiry'>
                        <CardExpiryElement />
                      </div>
                      <div className = 'card-cvc'>
                        <CardCVCElement />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <span className = 'color-error small margin-bottom'>{this.props.error}</span>
          {
            this.state.attemptPayment ? (
              <button className = 'checkout-button' disabled>Checkout</button>
            ) : <button className = 'checkout-button'>Checkout</button>
          }
        </form>
      </div>
    )
  }
}

export default injectStripe(InjectedCheckout)