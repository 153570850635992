import React, { PureComponent } from 'react'

import './styles.scss'

export default class BlogLoader extends PureComponent {
  render() {
    return (
      <div className = 'blog-loader'>
        <div className = 'blog-loader-title' />
        <div className = 'blog-loader-splash' />
        <div className = 'blog-loader-preview-text' />
        <div className = 'blog-loader-preview-text' />
        <div className = 'blog-loader-preview-text' />

        <div className = 'blog-loader-title' />
        <div className = 'blog-loader-splash' />
        <div className = 'blog-loader-preview-text' />
        <div className = 'blog-loader-preview-text' />
        <div className = 'blog-loader-preview-text' />

        <div className = 'blog-loader-title' />
        <div className = 'blog-loader-splash' />
        <div className = 'blog-loader-preview-text' />
        <div className = 'blog-loader-preview-text' />
        <div className = 'blog-loader-preview-text' />
      </div>
    )
  }
}
