import React, { PureComponent } from 'react'

import './styles.scss'

export default class HowItWorks extends PureComponent {
	render() {
		return (
			<div className = 'how-it-works'>
				<div className = 'how-it-works-container'>
					<div className = 'how-it-works-header'>
						<h2>How it works</h2>
					</div>
					<div className = 'how-it-works-steps'>
						<div className = 'step'>
							<div className = 'step-header'>
								<span className = 'step-number'>1</span>
								<h2 className = 'step-title'>Solve product management interview problems.</h2>
							</div>
							<p className = 'step-copy'>Get daily product management interview questions asked recently by top companies.</p>
						</div>
						<div className = 'step'>
							<div className = 'step-header'>
								<span className = 'step-number'>2</span>
								<h2 className = 'step-title'>Get community feedback. <span className = 'premium-step'>Premium</span></h2>
							</div>
							<p className = 'step-copy'>Verify your work and get actionable tips on how to improve.</p>
						</div>
						<div className = 'step'>
							<div className = 'step-header'>
								<span className = 'step-number'>3</span>
								<h2 className = 'step-title'>We send expert solutions. <span className = 'premium-step'>Premium</span></h2>
							</div>
							<p className = 'step-copy'>See how real product managers at top companies answer the same questions.</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}