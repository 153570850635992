import React, { PureComponent } from 'react'
import { Elements } from 'react-stripe-elements'

import InjectedCheckoutSettingsModal from '@/components/InjectedCheckoutSettingsModal'

export default class ElementsCheckoutSettings extends PureComponent {
  render() {
    if (!this.props.show) {
      return null
    }
    return (
      <Elements>
        <InjectedCheckoutSettingsModal
        	error = {this.props.error}
          updateBilling = {this.props.updateBilling}
          closeModal = {this.props.closeModal}
        />
      </Elements>
    )
  }
}
