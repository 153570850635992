import React, { PureComponent } from 'react'

import ProblemCard from '@/components/ProblemCard'
import ProblemLoaderCard from '@/components/ProblemLoaderCard'

export default class ProblemsList extends PureComponent {
	render() {
		return (
			<div>
				{
					this.props.error ? (
						<div className = 'no-questions-found'>
							<span>{this.state.error}</span>
						</div>
					) : this.props.fetching ? (
							<div>
								<ProblemLoaderCard />
								<ProblemLoaderCard />
								<ProblemLoaderCard />
								<ProblemLoaderCard />
								<ProblemLoaderCard />
								<ProblemLoaderCard />
								<ProblemLoaderCard />
								<ProblemLoaderCard />
								<ProblemLoaderCard />
							</div>
					) : this.props.problems ? (
						<div>
							{
								this.props.problems.map(problem => {
									return (
										<ProblemCard
											location = 'problems'
											problem = {problem}
											praiseProblem = {this.props.praiseProblem}
											route = {`/problems/${problem.slug}`}
										/>
									)
								})
							}
						</div>
					) : (
						<div>
							<ProblemLoaderCard />
							<ProblemLoaderCard />
							<ProblemLoaderCard />
							<ProblemLoaderCard />
							<ProblemLoaderCard />
							<ProblemLoaderCard />
							<ProblemLoaderCard />
							<ProblemLoaderCard />
							<ProblemLoaderCard />
						</div>
					)
				}
			</div>
		)
	}
}