import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import { STORAGE_KEY } from '@/lib/constants'

import './styles.scss'

export default class Onboarding01Basics extends PureComponent {
  state = {
    error: null,
    firstName: null,
    lastName: null,
    username: null,
    password: null,
    lowercase: false,
    uppercase: false,
    number: false,
    minimum: false,
    regex: false,
    lowercaseTest: /^(?=.*[a-z])/,
    uppercaseTest: /^(?=.*[A-Z])/,
    numberTest: /^(?=.*[0-9])/,
    regexTest: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    continueEnabled: false,
  }
  componentDidMount = async () => {
    if(this.props.user) {
      this.setState({
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        username: this.props.user.username,
      })
    }
  }
  setFirstName = (firstName) => {
    let continueEnabled = false
    if(this.state.regex && firstName && this.state.lastName && this.state.username) {
      continueEnabled = true
    }
    this.setState({
      firstName,
      continueEnabled,
    })
  }
  setLastName = (lastName) => {
    let continueEnabled = false
    if(this.state.regex && this.state.firstName && lastName && this.state.username) {
      continueEnabled = true
    }
    this.setState({
      lastName,
      continueEnabled,
    })
  }
  setUsername = (username) => {
    let continueEnabled = false
    if(this.state.regex && this.state.firstName && this.state.lastName && username) {
      continueEnabled = true
    }
    this.setState({
      username,
      continueEnabled,
    })
  }
  setPassword = (password) => {
    let lowercase = false, uppercase = false, number = false, minimum = false, regex = false, continueEnabled = false
    if(this.state.lowercaseTest.test(password)) {
      lowercase = true
    }
    if(this.state.uppercaseTest.test(password)) {
      uppercase = true
    }
    if(this.state.numberTest.test(password)) {
      number = true
    }
    if(password.length >= 8) {
      minimum = true
    }
    if(lowercase && number && uppercase && minimum) {
      regex = true
    }
    if(regex && this.state.firstName && this.state.lastName && this.state.username) {
      continueEnabled = true
    }
    this.setState({
      password,
      lowercase,
      uppercase,
      number,
      minimum,
      regex,
      continueEnabled,
    })
  }
  continue = async () => {
    this.setState({
      error: null
    })
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/users/onboarding/basics`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        username: this.state.username,
        password: this.state.password,
      })
    })
    const { auth, error } = await res.json()
    if (auth) {
      await this.props.checkProgress()
    } else {
      this.setState({
        error
      })
    }
  }
  render() {
    return (
      <div className = 'column align-items-center'>
        <div className = 'column text-align-center margin-bottom mobile-padding'>
          <h1 className = 'no-margin'>Welcome to Daily Product Prep</h1>
          <h3 className = 'thin'>Help the community get to know you better.</h3>
        </div>
        <div className = 'column align-items-center'>
          <div className = 'margin-bottom focusable column'>
            <span className = 'input-title'>First name</span>
            <input
              placeholder = 'Amelia'
              type = 'text'
              value = {this.state.firstName}
              onChange = {(ev) => this.setFirstName(ev.target.value)}
            />
          </div>
          <div className = 'margin-bottom focusable column'>
            <span className = 'input-title'>Last name</span>
            <input
              placeholder = 'Earhart'
              type = 'text'
              value = {this.state.lastName}
              onChange = {(ev) => this.setLastName(ev.target.value)}
            />
          </div>
          <div className = 'margin-bottom focusable column'>
            <span className = 'input-title'>Username</span>
            <input
              placeholder = 'meeley97'
              type = 'text'
              value = {this.state.username}
              onChange = {(ev) => this.setUsername(ev.target.value)}
            />
          </div>
          <div className = 'margin-bottom focusable column'>
            <span className = 'input-title'>Password</span>
            <input
              placeholder = 'Enter a password...'
              type = 'password'
              value = {this.state.password}
              onChange = {(ev) => this.setPassword(ev.target.value)}
            />
            {
              this.state.regex ? (
                <div className = 'onboarding-password-secure'>
                  <p>Your password is secure and you're all set!</p>
                </div>
              ) : (
                <div className = 'onboarding-password-list'>
                  <ul className = 'no-margin'>
                    <li className = {this.state.lowercase ? 'successful-bullets' : ''}>One lowercase letter</li>
                    <li className = {this.state.uppercase ? 'successful-bullets' : ''}>One uppercase letter</li>
                  </ul>
                  <ul className = 'no-margin'>
                    <li className = {this.state.number ? 'successful-bullets' : ''}>One number</li>
                    <li className = {this.state.minimum ? 'successful-bullets' : ''}>8 character minimum</li>
                  </ul>
                </div>
              )
            }
          </div>
          <div className = 'onboarding-continue focusable column'>
            <span className = 'error-text'>{this.state.error}</span>
            {
              this.state.continueEnabled ?
              <button onClick = {() => this.continue()}>Continue</button>
              : <button className = 'disabled-button' disabled>Continue</button>
            }
          </div>
        </div>
        <div className = 'small thin color-grey column align-items-center'>
          <span>You're currently logged in as {this.props.user.email}.</span>
          <span>If this isn't you, <NavLink to = {`/logout`} className = 'hover underline color-grey'>logout</NavLink> and try again.</span>
        </div>
      </div>
    )
  }
}
