import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import Hamburger from 'hamburger-react'

import Logo from '@/assets/logos/logo.svg'

import './styles.scss'

const HeaderLogo = () => (
  <div className = 'row align-items-center mobile-hidden'>
    <NavLink to = {`/`} >
      <div className = 'header-logo-container margin-right'>
      <img
        src = {Logo}
        alt = 'Daily Product Prep'
        className = 'header-logo'
      />
      </div>
    </NavLink>
    <div className = 'margin-right'>
      <NavLink to = {`/`} className = 'header-title'>Daily Product Prep</NavLink>
      <div className = 'header-subtitle'>
        {/* eslint-disable-next-line */}
        <a href = {`https://www.tryexponent.com?utm_source=dpp&utm_medium=affiliate&utm_campaign=dpp-header&ref=dpp`} target = '_blank' className = 'header-subtitle-link'>
          by Exponent
        </a>
      </div>
    </div>
  </div>
);

export default class Header extends PureComponent {
  state = {
    email: '',
    dropdown: false,
    isOpen: false,
    setOpen: false,
  }
  showDropdown = () => {
    this.setState({
      dropdown: this.state.dropdown ? false : true,
    })
  }
  setOpen = () => {
    this.setState({
      isOpen: this.state.isOpen ? false : true
    })
  }
  render() {
    if(this.props.user) {
      return (
        <div className = 'container'>
          <div className = 'header'>
            <div className = 'row align-items-center'>
              <HeaderLogo />
              <div className = 'mobile-only'>
                <Hamburger toggled = {this.state.isOpen} toggle = {this.setOpen} />
                {
                  this.state.isOpen ? (
                    <div className = 'hamburger-open'>
                    <NavLink to = {`/`} className = 'align-items-center'>
                      <button onClick = {this.setOpen} className = 'header-button'>Home</button>
                    </NavLink>
                      <NavLink to = {`/problems`} className = 'align-items-center'>
                        <button onClick = {this.setOpen} className = 'header-button'>Problems</button>
                      </NavLink>
                      {/* eslint-disable-next-line */}
                      <a href={`https://www.tryexponent.com/courses/pm?utm_source=dpp&utm_medium=affiliate&utm_campaign=dpp-nav&ref=dpp`} target = '_blank'>
                        <button onClick = {this.setOpen} className = 'header-button'>Course</button>
                      </a>
                      {
                        this.props.user.type === 'free' ? (
                          <NavLink to = {`/pricing`} className = 'align-items-center'>
                            <button onClick = {this.setOpen} className = 'header-button'>Upgrade</button>
                          </NavLink>
                        ) : null
                      }
                      <NavLink to = {`/blog`} className = 'align-items-center'>
                        <button onClick = {this.setOpen} className = 'header-button'>Blog</button>
                      </NavLink>
                    </div>
                  ) : null
                }
              </div>
              <NavLink to = {`/problems`} className = 'row align-items-center mobile-hidden'>
                <button className = 'header-button'>Problems</button>
              </NavLink>
              {/* eslint-disable-next-line */}
              <a href={`https://www.tryexponent.com/courses/pm?utm_source=dpp&utm_medium=affiliate&utm_campaign=dpp-nav&ref=dpp`} target = '_blank'>
                <button onClick = {this.setOpen} className = 'header-button'>Course</button>
              </a>
              {
                this.props.user.type === 'free' ? (
                  <NavLink to = {`/pricing`} className = 'row align-items-center mobile-hidden'>
                    <button className = 'header-button'>Upgrade</button>
                  </NavLink>
                ) : null
              }
              <NavLink to = {`/blog`} className = 'row align-items-center mobile-hidden'>
                <button className = 'header-button'>Blog</button>
              </NavLink>
            </div>
            <div className = 'header-dropdown'>
              <button className = 'row align-items-center header-avatar-button' onClick = {() => this.showDropdown()}>
                <img
                  src = {this.props.user.avatarURL}
                  alt = 'Avatar'
                  className = 'header-avatar'
                />
                <span className = 'header-name'>{this.props.user.firstName} {this.props.user.lastName}</span>
              </button>
              <div className = {this.state.dropdown ? 'header-dropdown-content show' : 'header-dropdown-content'}>
                <NavLink to = {`/@/${this.props.user.username}`}>
                  <button onClick = {() => this.setState({ dropdown: false })}>Profile</button>
                </NavLink>
                <NavLink to = {`/settings/profile`}>
                  <button onClick = {() => this.setState({ dropdown: false })}>Settings</button>
                </NavLink>
                <NavLink to = {`/logout`}>
                  <button onClick = {() => this.setState({ dropdown: false })}>Logout</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
    	<div className = 'container'>
        <div className = 'header'>
          <div className = 'row align-items-center'>
            <HeaderLogo />
            <div className = 'mobile-only'>
              <Hamburger toggled = {this.state.isOpen} toggle = {this.setOpen} />
              {
                this.state.isOpen ? (
                  <div className = 'hamburger-open'>
                    <NavLink to = {`/`} className = 'align-items-center'>
                      <button onClick = {this.setOpen} className = 'header-button'>Home</button>
                    </NavLink>
                    <NavLink to = {`/problems`} className = 'align-items-center'>
                      <button onClick = {this.setOpen} className = 'header-button'>Problems</button>
                    </NavLink>
                    {/* eslint-disable-next-line */}
                    <a href={`https://www.tryexponent.com/courses/pm?utm_source=dpp&utm_medium=affiliate&utm_campaign=dpp-nav&ref=dpp`} target = '_blank'>
                      <button onClick = {this.setOpen} className = 'header-button'>Course</button>
                    </a>
                    <NavLink to = {`/pricing`} className = 'align-items-center'>
                      <button onClick = {this.setOpen} className = 'header-button'>Pricing</button>
                    </NavLink>
                    <NavLink to = {`/blog`} className = 'align-items-center'>
                      <button onClick = {this.setOpen} className = 'header-button'>Blog</button>
                    </NavLink>
                  </div>
                ) : null
              }
            </div>
            <NavLink to = {`/problems`} className = 'row align-items-center mobile-hidden'>
              <button className = 'header-button'>Problems</button>
            </NavLink>
            {/* eslint-disable-next-line */}
            <a href={`https://www.tryexponent.com/courses/pm?utm_source=dpp&utm_medium=affiliate&utm_campaign=dpp-nav&ref=dpp`} target = '_blank'>
              <button onClick = {this.setOpen} className = 'header-button'>Course</button>
            </a>
            <NavLink to = {`/pricing`} className = 'row align-items-center mobile-hidden'>
              <button className = 'header-button'>Pricing</button>
            </NavLink>
            <NavLink to = {`/blog`} className = 'row align-items-center mobile-hidden'>
              <button className = 'header-button'>Blog</button>
            </NavLink>
          </div>
          <div className = 'row align-items-center mobile-hidden'>
            <NavLink to = {`/login`} className = 'row align-items-center'>
              <button className = 'header-button'>Log in</button>
            </NavLink>
            <div className = 'header-sign-up'>
              <input
                placeholder = 'Enter your email...'
                type = 'email'
                className = 'input'
                value = {this.state.email}
                onChange = {(ev) => this.setState({ email: ev.target.value })}
              />
              <NavLink to = {{pathname: `/signup`, email: this.state.email}} className = 'row align-items-center header-sign-up'>
                <button>Sign up</button>
              </NavLink>
            </div>
          </div>
          <div className = 'mobile-only'>
            <NavLink to = {`/login`} className = 'row align-items-center'>
              <button className = 'header-button'>Log in</button>
            </NavLink>
            <NavLink to = {`/signup`} className = 'row align-items-center header-sign-up'>
              <button>Sign up</button>
            </NavLink>
          </div>
        </div>
    	</div>
    )
  }
}
