function isWholeNumber(number) {
  return Number.isInteger(parseFloat(parseFloat(number).toFixed(2)))
}

export function humanizePrice(number) {
  if(isWholeNumber(number)) {
    return number.toFixed(0)
  } else {
    return parseFloat(number).toFixed(2)
  }
}
export function humanizeNumber(number) {
	if(number > 999) {
		if(isWholeNumber(number/1000)) {
			return `${number/1000}k`
		} else {
			return `${(number/1000).toFixed(1)}k`
		}
	} else {
		return number
	}
}