import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { trackPageView } from '@/lib/analytics'

import Loader from '@/components/Loader'

import './styles.scss'

export default class ResetPassword extends PureComponent {
	state = {
		error: null,
		password: '',
		confirmPassword: '',
		lowercase: false,
    uppercase: false,
    number: false,
    minimum: false,
    matching: false,
    regex: false,
    lowercaseTest: /^(?=.*[a-z])/,
    uppercaseTest: /^(?=.*[A-Z])/,
    numberTest: /^(?=.*[0-9])/,
    regexTest: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
		buttonEnabled: false,
		passwordReset: false,
		requesting: false,
	}
	componentDidMount = () => {
		trackPageView(window.location.pathname + window.location.search)
		if(this.props.user) {
			this.props.history.push('/')
		}
	}
	updatePassword = async () => {
    this.setState({
    	requesting: true,
      error: null,
    })
    let params = await new URLSearchParams(this.props.history.location.search)
    const token = params.get('token')
    const res = await fetch (`/api/users/set-password/${token}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      },
      body: JSON.stringify({
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
    	setTimeout(() => {
    		this.setState({
	    		passwordReset: true,
	    		requesting: false,
	    		error: false,
	    	})
    	}, 1000)
    } else {
      this.setState({
        error,
        requesting: false,
      })
    }
	}
	setPassword = async (password) => {
    let lowercase = false, uppercase = false, number = false, minimum = false, regex = false, buttonEnabled = false, matching = false
    if(this.state.lowercaseTest.test(password)) {
      lowercase = true
    }
    if(this.state.uppercaseTest.test(password)) {
      uppercase = true
    }
    if(this.state.numberTest.test(password)) {
      number = true
    }
    if(password.length >= 8) {
      minimum = true
    }
    if(this.state.confirmPassword === password && password.length > 0) {
    	matching = true
    }
    if(lowercase && number && uppercase && minimum && matching) {
      regex = true
    }
    if(regex && this.state.confirmPassword) {
      buttonEnabled = true
    }
    await this.setState({
      password,
      lowercase,
      uppercase,
      number,
      minimum,
      matching,
      regex,
      buttonEnabled,
    })
  }
  setConfirmPassword = async (confirmPassword) => {
    let buttonEnabled = false, matching = false, regex = false
    if(this.state.password === confirmPassword && this.state.password.length > 1) {
    	matching = true
    	if(this.state.lowercase && this.state.number && this.state.uppercase && this.state.minimum) {
    		regex = true
    	}
    }
    await this.setState({
			regex,
			matching,
		})
    if(this.state.regex && this.state.password) {
      buttonEnabled = true
    }
    await this.setState({
      confirmPassword,
      buttonEnabled,
    })
  }
	render() {
		if(this.state.requesting) {
			return (
				<div className = 'column align-items-center'>
					<Helmet>
            <title>Interview Prep for Product Jobs - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<div className = 'auth-title'>Set password</div>
					<Loader />
				</div>
			)
		}
		return (
			<div className = 'column align-items-center'>
				<Helmet>
          <title>Interview Prep for Product Jobs - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<div className = 'auth-title'>Set password</div>
				{
					this.state.passwordReset ? (
						<div className = 'column mobile-padding'>
							<span className = 'text-align-center color-grey margin-bottom'>You've successfully changed your password.</span>
							<NavLink to = {`/login`}>
								<button>Login</button>
							</NavLink>
						</div>
					)
					: (
						<div className = 'column align-items-center mobile-padding'>
							<div className = 'margin-bottom focusable column'>
								<span className = 'input-title'>Password</span>
								<input
									placeholder = 'Enter your password...'
									type = 'password'
									className = 'auth-input'
									value = {this.state.password}
									onChange = {(ev) => this.setPassword(ev.target.value)}
								/>
							</div>
							<div className = 'margin-bottom focusable column'>
								<span className = 'input-title'>Confirm password</span>
								<input
									placeholder = 'Confirm password...'
									type = 'password'
									className = 'auth-input'
									value = {this.state.confirmPassword}
									onChange = {(ev) => this.setConfirmPassword(ev.target.value)}
								/>
							</div>
							{
	              this.state.regex ? (
	                <div className = 'set-password-password-secure'>
	                  <p>Your password is secure and you're all set!</p>
	                </div>
	              ) : (
	                <div className = 'set-password-password-list'>
	                  <ul className = 'no-margin'>
	                    <li className = {this.state.lowercase ? 'successful-bullets' : ''}>One lowercase letter</li>
	                    <li className = {this.state.uppercase ? 'successful-bullets' : ''}>One uppercase letter</li>
	                    <li className = {this.state.confirmPassword === this.state.password && this.state.password.length > 0 ? 'successful-bullets' : ''}>Passwords matching</li>
	                  </ul>
	                  <ul className = 'no-margin'>
	                    <li className = {this.state.number ? 'successful-bullets' : ''}>One number</li>
	                    <li className = {this.state.minimum ? 'successful-bullets' : ''}>8 character minimum</li>
	                  </ul>
	                </div>
	              )
	            }
							<div className = 'margin-bottom focusable column'>
								<span className = 'error-text'>{this.state.error}</span>
								{
									this.state.buttonEnabled ? (
										<button onClick = {() => this.updatePassword()}>Set password</button>
									) : <button className = 'disabled-button' disabled>Set password</button>
								}
							</div>
						</div>
					)
				}
				{
					this.state.passwordReset ? null
					: <p className = 'margin-bottom small color-grey'>Or, <NavLink to = {`/login`} className = 'underline hover color-grey'>log in with email</NavLink>.</p>
				}
			</div>
		)
	}
}