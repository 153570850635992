import React, { PureComponent } from 'react'
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements'
import ReCAPTCHA from 'react-google-recaptcha'

import '@/_modals.scss'

const recaptchaRef = React.createRef()

class InjectedCheckoutSettingsModal extends PureComponent {
  state = {
    email: null,
    attemptPayment: false,
    success: false,
  }
  onSubmit = (ev) => {
    ev.preventDefault()
    recaptchaRef.current.execute()
  }
  onChange = async () => {
    const recaptchaValue = recaptchaRef.current.getValue()
    this.setState({ attemptPayment: true })
    const token = await this.props.stripe.createToken({ type: 'card' })
    if (token) {
      await this.props.updateBilling(token, recaptchaValue)
    } 
    this.setState({ attemptPayment: false })
  }
  render() {
    const sitekey = '6Ld3B8gUAAAAADTXcA4lzsMdM6G6cc3blCacRrPO'
    return (
      <div className = 'modal-overlay'>
        <div className = 'modal cc'>
          <form onSubmit = {this.onSubmit}>
            <ReCAPTCHA
              sitekey = {sitekey}
              size = 'invisible'
              ref = {recaptchaRef}
              onChange = {this.onChange}
            />
            <div className = 'column'>
              <div className = 'checkout-subheader'>
                <span>Payment details</span>
              </div>
              <div className = 'checkout-entry-section'>
                <div className = 'margin-bottom column'>
                  <span className = 'input-title'>Card information</span>
                  <fieldset className = 'payment-fieldset'>
                    <div>
                      <div className = 'payment-number-input'>
                        <CardNumberElement />
                      </div>
                      <div className = 'payment-additional-input'>
                        <div className = 'card-expiry'>
                          <CardExpiryElement />
                        </div>
                        <div className = 'card-cvc'>
                          <CardCVCElement />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <span className = 'color-error small margin-bottom'>{this.props.error}</span>
            {
              this.state.attemptPayment ? (
                <button className = 'checkout-button' disabled>Updating...</button>
              ) : <button className = 'checkout-button'>Update</button>
            }
          </form>
          <div className = 'text-align-center margin-top'>
            <span onClick = {this.props.closeModal} className = 'underline hover small'>Cancel</span>
          </div>
        </div>
      </div>
    )
  }
}

export default injectStripe(InjectedCheckoutSettingsModal)