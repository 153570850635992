import React, { PureComponent } from 'react'
import { NavHashLink as NavLink } from 'react-router-hash-link'

import './styles.scss'

export default class FAQ extends PureComponent {
  render() {
    return (
      <div className = 'column' id = 'faq'>
        <div className = 'pricing-subheader'>
          <span>FAQ</span>
        </div>
        <div className = 'faq'>
          <span className = 'faq-question'>Do you have questions from Google / Facebook / Microsoft / Amazon?</span>
          <span className = 'faq-answer'>Every single one of our questions have been asked in an actual interview from a top-tier tech company. They are also among the more difficult problems that are asked—our philosophy is that it's better to be overprepared than underprepared. With our subscription, you'll get an in-depth explanation and solution for each hard problem the day after we send it.</span>
        </div>
        <div className = 'faq'>
          <span className = 'faq-question'>I don't have experience as product manager. Should I still subscribe to Daily Product Prep?</span>
          <span className = 'faq-answer'>While the problems we send rely on knowledge of basic product management principles, it is not necessary to have product management experience. In fact <strong>many of our subscribers are here to enter into product management.</strong> Some concepts may initially be unfamiliar, however we do our best to explain any new or complex concepts. Within only a few weeks, you'll be thinking like a product manager!</span>
        </div>
        <div className = 'faq'>
          <span className = 'faq-question'>Why not Cracking the PM Interview or Product Bootcamps?</span>
          <span className = 'faq-answer'>We found full-time students and workers are too busy to slog through a gigantic list of questions or dedicate full-time hours to a bootcamp. With our mailing list, you just have to attempt one question every day. Consistent practice is the best way to learn. We also make sure the questions are carefully tailored to match the difficulty of actual interview problems asked at top-tier companies. Finally, getting a problem each day and then the solution the day after gives you a day to think about the question during idle periods of time, which helps with understanding and retention.</span>
        </div>
        <div className = 'faq'>
          <span className = 'faq-question'>How long should I spend solving each problem?</span>
          <span className = 'faq-answer'>Each problem is designed to be able to be solved in 30-minutes, like in a real-life interview.</span>
        </div>
        <div className = 'faq'>
          <span className = 'faq-question'>Is Daily Product Prep really worth it?</span>
          <span className = 'faq-answer'>The typical Google / Facebook / Microsoft / Amazon compensation for someone with no experience is about $150,000 in total compensation (as in, base salary, bonus, and equity). Let's say you have a 25% better chance of getting the job studying with Daily Product Prep (which we believe to be true, if not more) for 2 months. And let's say that without studying, you can land a salary of $80,000 (typical pm salary). Then you've paid $30 for an expected value of $17,500 (($150,000 - $80,000) * 25%), which is a return on investment of almost 600 times! That's well worth it, in our opinion.</span>
        </div>
        <div className = 'faq'>
          <span className = 'faq-question'>I subscribed but I'm not receiving emails.</span>
          <span className = 'faq-answer'>If you haven't immediately received an email after signing up, please check your spam folder and make sure our emails haven't gone there. If that's the case, then please whitelist our email to make sure it doesn't go into spam in the future. If that's not the case, please email us at <span className = 'linkText'>daily@dailyproductprep.com</span> and we'll resolve the issue.</span>
        </div>
        <div className = 'pricing-cta'>
          <NavLink smooth to = '/pricing#plans'>
            <button>Get started</button>
          </NavLink>
        </div>
      </div>
    )
  }
}
