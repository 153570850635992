import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'

import { STORAGE_KEY } from '@/lib/constants'
import { trackPageView } from '@/lib/analytics'

import ElementsCheckoutSettings from '@/components/ElementsCheckoutSettings'
import SettingsSidebar from '@/components/SettingsSidebar'
import Loader from '@/components/Loader'

import CheckmarkWhite from '@/assets/iconography/checkmarkWhite.svg'
import './styles.scss'

export default class BillingSettings extends PureComponent {
	state = {
		user: null,
		loading: true,
		updating: false,
		success: false,
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		await this.setInitialUser()
		this.setState({
			loading: false
		})
	}
	setInitialUser = async () => {
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/users/me`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { auth, user, error } = await res.json()
    if(auth) {
    	this.setState({
    		user,
    		error,
    	})
    } else {
    	this.setState({
    		user,
    		error,
    	})
    }
	}
	updateBilling = async (paymentToken, recaptchaValue) => {
    if(paymentToken.error) {
      this.setState({
        error: paymentToken.error.message,
      })
    } else {
    	const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
	    const res = await fetch(`/api/users/billing`, {
	      method: 'PUT',
	      headers: {
	        'Content-Type': 'application/json',
	        'Access-Control-Allow-Headers': 'Origin',
	        'Authorization': 'Bearer ' + DEMO_TOKEN,
	        'x-access-token': DEMO_TOKEN
	      },
	      body: JSON.stringify({
	        paymentToken: paymentToken.token.id,
	        recaptchaValue,
	      })
	    })
	    const { auth, error } = await res.json()
	    if(auth) {
	    	await this.setInitialUser()
	      this.setState({
	        success: true,
	        showUpdateCard: false,
	        error,
	      })
	      setTimeout(() => {
	    		this.setState({
		    		success: false,
		    	})
	    	}, 1500)
	    } else {
	      this.setState({
	        error,
	      })
	    }
    }
	}
	keepSubscription = async () => {
		this.setState({
			updating: true,
		})
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
	    const res = await fetch(`/api/subscriptions/keep`, {
	      method: 'PUT',
	      headers: {
	        'Content-Type': 'application/json',
	        'Access-Control-Allow-Headers': 'Origin',
	        'Authorization': 'Bearer ' + DEMO_TOKEN,
	        'x-access-token': DEMO_TOKEN
	      }
	    })
	    const { auth, error } = await res.json()
	    if(auth) {
	    	await this.setInitialUser()
	      this.setState({
	        success: true,
	        updating: false,
	        error,
	      })
	      setTimeout(() => {
	    		this.setState({
		    		success: false,
		    	})
	    	}, 1500)
	    } else {
	      this.setState({
	      	updating: false,
	        error,
	      })
	    }
	}
	render() {
		if(this.state.loading) {
			return (
				<div className = 'auth-title'>
					<Helmet>
            <title>Billing</title>
            <meta
              name = 'description'
              content="Update your billing settings for Daily Product Prep and get a product management job from top companies like Netflix, Google, Uber, and more."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<Loader />
				</div>
			)
		}
		if(!this.state.user) {
			return (
				<div className = 'profile-not-found'>
					<Helmet>
            <title>Billing</title>
            <meta
              name = 'description'
              content="Update your billing settings for Daily Product Prep and get a product management job from top companies like Netflix, Google, Uber, and more."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<h1>You're not logged in.</h1>
					<NavLink to = {`/login`}>
						<button>Login...</button>
					</NavLink>
				</div>
			)
		}
		const stripeKey = process.env.NODE_ENV === 'production' ? 'pk_live_ONMbjDDucrQCXB5D4ukTTUVY00udpDOCuK' : 'pk_test_jXVzPY1gJsxeePCC2FGoFTf900diiczt8N'
		return (
			<StripeProvider apiKey = {stripeKey}>
				<div className = 'profile padding-top'>
					<Helmet>
	          <title>Billing</title>
	          <meta
	            name = 'description'
	            content="Update your billing settings for Daily Product Prep and get a product management job from top companies like Netflix, Google, Uber, and more."
	          />
	          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:type" content="image/png" />
	          <meta property="og:image:width" content="1200" />
	          <meta property="og:image:height" content="630" />
	          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
	        </Helmet>
					<ElementsCheckoutSettings
						show = {this.state.showUpdateCard}
						error = {this.state.error}
						updateBilling = {this.updateBilling}
						closeModal = {() => this.setState({ showUpdateCard: false })}
					/>
					<div className = 'profile-grid'>
						<SettingsSidebar
							route = {`Billing`}
						/>
						<div className = 'settings-main'>
							<div className = 'settings-input-item'>
		            <span className = 'input-title'>Membership</span>
		            {
		            	this.state.user.type === 'premium' && this.state.user.subscription && this.state.user.subscription.cancel_at_period_end ? (
		            		<div className = 'row align-items-center'>
		            			<div className = 'badge cancels'>
		            				<p>Cancels soon</p>
		            			</div>
		            			{
		            				this.state.updating ? (
		            					<p className = 'underline small color-primary hover margin-left'>Renew Premium</p>
		            				) : <p className = 'underline small color-primary hover margin-left' onClick = {() => this.keepSubscription()}>Renew Premium</p>
		            			}
		            		</div>
		            	) : this.state.user.type === 'premium' ? (
		            		<div className = 'row align-items-center'>
		            			<div className = 'badge premium'>
		            				<p>{this.state.user.plan}</p>
		            			</div>
		            			<NavLink to = {`/pricing`} className = 'margin-left'>
		            				<p className = 'underline small color-primary'>Change plan</p>
		            			</NavLink>
		            		</div>
		            	) : (
		            		<div className = 'row align-items-center'>
		            			<div className = 'badge free'>
		            				<p>Free</p>
		            			</div>
		            			<NavLink to = {`/pricing`} className = 'margin-left'>
		            				<p className = 'underline small color-primary'>Upgrade</p>
		            			</NavLink>
		            		</div>
		            	)
		            }
	            </div>
	            {
	            	this.state.user.billing ? (
	            		<div className = 'settings-input-item'>
				            <span className = 'input-title'>Payment details</span>
				            <div className = 'row align-items-center'>
		            			<span>{this.state.user.billing.brand} ···· ···· ···· {this.state.user.billing.last4} {this.state.user.billing.exp_month} / {this.state.user.billing.exp_year}</span>
		            			<p className = 'underline small color-primary margin-left hover' onClick = {() => this.setState({ showUpdateCard: true })}>Update card</p>
		            		</div>
			            </div>
	            	) : null
	            }
	            <div className = 'settings-input-item'>
		            {
	            		this.state.updating ? (
	            			<button className = 'disabled-button' disabled>
	            				<span>Saving...</span>
	            			</button>
	            		) : this.state.success ? (
	            			<button className = 'settings-success' disabled>
	            				<img
	            					src = {CheckmarkWhite}
	            					alt = 'Daily Product Prep'
	            				/>
	            				<span>Saved</span>
	            			</button>
	            		) : null
	            	}
            	</div>
						</div>
					</div>
				</div>
			</StripeProvider>
		)
	}
}