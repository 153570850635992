import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

import { trackPageView } from '@/lib/analytics'

import AcceptTermsAndPrivacy from '@/components/AcceptTermsAndPrivacy'
import Loader from '@/components/Loader'

export default class LogIn extends PureComponent {
	state = {
		error: null,
		forgotPassword: false,
		email: '',
		password: '',
		passwordReset: false,
		requesting: false,
	}
	componentDidMount = () => {
		trackPageView(window.location.pathname + window.location.search)
		if(this.props.user) {
			this.props.history.push('/')
		}
	}
	login = async () => {
    this.setState({
    	requesting: true,
      error: null,
    })
    const res = await fetch (`/api/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      })
    })
    const { auth, token, error } = await res.json()
    if(auth) {
      await this.props.login(token)
      await this.props.history.push(`/`)
    } else {
      this.setState({
        error,
        requesting: false,
      })
    }
	}
	resetPassword = async () => {
		this.setState({
			error: null,
			requesting: true,
		})
		const res = await fetch(`/api/users/reset-password/${this.state.email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      }
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        passwordReset: true,
        error,
        requesting: false,
      })
    } else {
      this.setState({
        error,
        requesting: false,
      })
    }
	}
	render() {
		if(this.state.requesting) {
			return (
				<div className = 'column align-items-center'>
					<Helmet>
            <title>Login</title>
            <meta
              name = 'description'
              content="Login to Daily Product Prep and get access to product management interview prep questions to help you land your next product manager job."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					{
						this.state.forgotPassword ?
						<div className = 'auth-title'>Reset password</div>
						: <div className = 'auth-title'>Log in</div>
					}
					<Loader />
					<AcceptTermsAndPrivacy
						title = {`Log in`}
					/>
				</div>
			)
		}
		return (
			<div className = 'column align-items-center'>
				<Helmet>
          <title>Login</title>
          <meta
            name = 'description'
            content="Login to Daily Product Prep and get access to product management interview prep questions to help you land your next product manager job."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				{
					this.state.forgotPassword ?
					<div className = 'auth-title'>Reset password</div>
					: <div className = 'auth-title'>Log in</div>
				}
				{
					this.state.passwordReset ?
					<span className = 'text-align-center color-grey'>Check your inbox for the link to reset your password.</span>
					: (
						<div className = 'column align-items-center'>
							<div className = 'margin-bottom focusable column'>
								<span className = 'input-title'>Email</span>
								<input
									placeholder = 'Enter your email address...'
									type = 'email'
									className = 'auth-input'
									value = {this.state.email}
									onChange = {(ev) => this.setState({ email: ev.target.value })}
								/>
							</div>
							{
								this.state.forgotPassword ? null
								: (
									<div className = 'margin-bottom focusable column'>
										<span className = 'input-title'>Password</span>
										<input
											placeholder = 'Enter your password...'
											type = 'password'
											className = 'auth-input'
											value = {this.state.password}
											onChange = {(ev) => this.setState({ password: ev.target.value })}
										/>
									</div>
								)
							}
							<div className = 'margin-bottom focusable column'>
								<span className = 'error-text'>{this.state.error}</span>
								{
									this.state.forgotPassword ?
									<button onClick = {() => this.resetPassword()}>Reset password</button>
									: <button onClick = {() => this.login()}>Log in</button>
								}
							</div>
						</div>
					)
				}
				{
					this.state.forgotPassword ?
					<p className = 'margin-bottom small color-grey'>Or, <span onClick = {() => this.setState({ forgotPassword: false, passwordReset: false })} className = 'underline hover color-grey'>log in with email</span>.</p>
					: <p onClick = {() => this.setState({ forgotPassword: true })} className = 'margin-bottom small underline hover color-grey'>Forgot password?</p>
				}
				<AcceptTermsAndPrivacy
					title = {`Log in`}
				/>
			</div>
		)
	}
}