import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

import { trackPageView } from '@/lib/analytics'

import Hero from '@/components/Hero'
import SampleProblem from '@/components/SampleProblem'
import HowItWorks from '@/components/HowItWorks'
import SuccessStories from '@/components/SuccessStories'

export default class Splash extends PureComponent {
	state = {
		problems: null
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		await this.fetchSamples()
	}
	fetchSamples = async (code) => {
    const res = await fetch(`/api/problems/samples`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    })
    const { problems, error } = await res.json()
    this.setState({
      problems,
      error,
    })
  }
	render() {
		return (
			<div className = 'column'>
				<Helmet>
          <title>Interview Prep for Product Jobs - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<Hero />
				{
					this.state.problems ? (
						<SampleProblem
							problems = {this.state.problems}
						/>
					) : null
				}
				<HowItWorks />
				<SuccessStories />
			</div>
		)
	}
}