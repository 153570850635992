import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { STORAGE_KEY } from '@/lib/constants'
import { trackPageView } from '@/lib/analytics'

import SettingsSidebar from '@/components/SettingsSidebar'

import CheckmarkWhite from '@/assets/iconography/checkmarkWhite.svg'

export default class SecuritySettings extends PureComponent {
	state = {
		email: null,
		password: null,
		newPassword: null,
		updating: false,
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		if(this.props.user) {
			await this.setInitialUser()
		}
	}
	setInitialUser = () => {
		this.setState({
			email: this.props.user.email,
		})
	}
	updateSecurity = async () => {
		this.setState({
			updating: true,
		})
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/users/security`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
        newPassword: this.state.newPassword,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        success: true,
        updating: false,
        error,
      })
      setTimeout(() => {
    		this.setState({
	    		success: false,
	    	})
    	}, 1500)
    } else {
      this.setState({
        updating: false,
        error,
      })
    }
	}
	render() {
		if(!this.props.user) {
			return (
				<div className = 'profile-not-found'>
					<Helmet>
            <title>Settings - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<h1>You're not logged in.</h1>
					<NavLink to = {`/login`}>
						<button>Login...</button>
					</NavLink>
				</div>
			)
		}
		return (
			<div className = 'profile padding-top'>
				<Helmet>
          <title>Settings - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<div className = 'profile-grid'>
					<SettingsSidebar
						route = {`Security`}
					/>
					<div className = 'settings-main'>
						<div className = 'settings-input-item'>
	            <span className = 'input-title'>Email</span>
	            <input
	              placeholder = 'Enter your email...'
	              type = 'text'
	              value = {this.state.email}
	              onChange = {(e) => this.setState({ email: e.target.value })}
	              className = 'settings-input'
	            />
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Password</span>
	            <input
	              placeholder = 'Enter your password...'
	              type = 'password'
	              value = {this.state.password}
	              onChange = {(e) => this.setState({ password: e.target.value })}
	              className = 'settings-input'
	            />
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>New password</span>
	            <input
	              placeholder = 'Enter a new password...'
	              type = 'password'
	              value = {this.state.newPassword}
	              onChange = {(e) => this.setState({ newPassword: e.target.value })}
	              className = 'settings-input'
	            />
            </div>
            <div className = 'settings-input-item'>
            	<span className = 'error-text'>{this.state.error}</span>
            	{
            		this.state.success ? (
            			<button className = 'settings-success' disabled>
            				<img
            					src = {CheckmarkWhite}
            					alt = 'Daily Product Prep'
            				/>
            				<span>Saved</span>
            			</button>
            		) : this.state.updating ? (
            			<button className = 'disabled-button' disabled>
            				<span>Saving...</span>
            			</button>
            		) : (
            			<button onClick = {() => this.updateSecurity()}>
          					<span>Save</span>
          				</button>
          			)
            	}
            </div>
					</div>
				</div>
			</div>
		)
	}
}