import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { STORAGE_KEY } from '@/lib/constants'
import { trackPageView } from '@/lib/analytics'

import SettingsSidebar from '@/components/SettingsSidebar'

import CheckmarkWhite from '@/assets/iconography/checkmarkWhite.svg'
import './styles.scss'

export default class ProfileSettings extends PureComponent {
	state = {
		firstName: null,
		lastName: null,
		username: null,
		location: null,
    status: null,
    bio: null,
    linkedInURL: null,
    updating: false,
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		if(this.props.user) {
			await this.setInitialUser()
		}
	}
	setInitialUser = () => {
		this.setState({
			firstName: this.props.user.firstName,
			lastName: this.props.user.lastName,
			username: this.props.user.username,
			location: this.props.user.about ? this.props.user.about.location : null,
	    status: this.props.user.about ? this.props.user.about.status : null,
	    bio: this.props.user.about ? this.props.user.about.bio : null,
	    linkedInURL: this.props.user.about ? this.props.user.about.linkedInURL : null,
		})
	}
	updateProfile = async () => {
		this.setState({
			updating: true,
		})
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/users/profile`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        username: this.state.username,
        location: this.state.location,
        status: this.state.status,
        bio: this.state.bio,
        linkedInURL: this.state.linkedInURL,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
    	await this.props.fetchUser()
      this.setState({
        success: true,
        updating: false,
        error,
      })
      setTimeout(() => {
    		this.setState({
	    		success: false,
	    	})
    	}, 1500)
    } else {
      this.setState({
        updating: false,
        error,
      })
    }
	}
	render() {
		if(!this.props.user) {
			return (
				<div className = 'profile-not-found'>
					<Helmet>
            <title>Settings - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<h1>You're not logged in.</h1>
					<NavLink to = {`/login`}>
						<button>Login...</button>
					</NavLink>
				</div>
			)
		}
		return (
			<div className = 'profile padding-top'>
				<Helmet>
          <title>Settings - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<div className = 'profile-grid'>
					<SettingsSidebar
						route = {`Profile`}
					/>
					<div className = 'settings-main'>
						<div className = 'settings-input-item'>
	            <span className = 'input-title'>First name</span>
	            <input
	              placeholder = 'Amelia'
	              type = 'text'
	              value = {this.state.firstName}
	              onChange = {(e) => this.setState({ firstName: e.target.value })}
	              className = 'settings-input'
	            />
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Last name</span>
	            <input
	              placeholder = 'Earhart'
	              type = 'text'
	              value = {this.state.lastName}
	              onChange = {(e) => this.setState({ lastName: e.target.value })}
	              className = 'settings-input'
	            />
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Username</span>
	            <input
	              placeholder = 'meeley97'
	              type = 'text'
	              value = {this.state.username}
	              onChange = {(e) => this.setState({ username: e.target.value })}
	              className = 'settings-input'
	            />
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Bio</span>
	            <textarea
	              placeholder = 'Add a bio...'
	              type = 'text'
	              value = {this.state.bio}
	              onChange = {(e) => this.setState({ bio: e.target.value })}
	              className = 'settings-input'
	              rows = {5}
	            />
	            {
	            	this.state.bio ? (
	            		<span className = {250 - this.state.bio.length < 0 ? 'color-error small' : 'small'}>{250 - this.state.bio.length} characters remaining.</span>
	            	) : null
	            }
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Location</span>
	            <input
	              placeholder = 'Atchison, Kansas'
	              type = 'text'
	              value = {this.state.location}
	              onChange = {(e) => this.setState({ location: e.target.value })}
	              className = 'settings-input'
	            />
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Status</span>
	            <div className = 'settings-button-row'>
		            <button className = {this.state.status === 'seeker' ? null : 'unselected'} onClick = {() => this.setState({ status: 'seeker' })}>Job seeker</button>
		            <button className = {this.state.status === 'interviewer' ? null : 'unselected'} onClick = {() => this.setState({ status: 'interviewer' })}>Interviewer</button>
		            <button className = {this.state.status === 'recruiter' ? null : 'unselected'} onClick = {() => this.setState({ status: 'recruiter' })}>Recruiter</button>
		            <button className = {this.state.status === 'student' ? null : 'unselected'} onClick = {() => this.setState({ status: 'student' })}>Student</button>
	            </div>
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>LinkedIn</span>
	            <input
	              placeholder = 'https://www.linkedin.com/in/ameliaearhart/'
	              type = 'text'
	              value = {this.state.linkedInURL}
	              onChange = {(e) => this.setState({ linkedInURL: e.target.value })}
	              className = 'settings-input'
	            />
            </div>
            <div className = 'settings-input-item'>
            	<span className = 'error-text'>{this.state.error}</span>
            	{
            		this.state.success ? (
            			<button className = 'settings-success' disabled>
            				<img
            					src = {CheckmarkWhite}
            					alt = 'Daily Product Prep'
            				/>
            				<span>Saved</span>
            			</button>
            		) : this.state.updating ? (
            			<button className = 'disabled-button' disabled>
            				<span>Saving...</span>
            			</button>
            		) : (
            			<button onClick = {() => this.updateProfile()}>
          					<span>Save</span>
          				</button>
          			)
            	}
            </div>
					</div>
				</div>
			</div>
		)
	}
}