import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

import { trackPageView } from '@/lib/analytics'

import BlogLoader from '@/components/BlogLoader'
import BlogPreview from '@/components/BlogPreview'
import BlogCTA from '@/components/BlogCTA'

import './styles.scss'

export default class Blog extends PureComponent {
  state = {
    posts: [],
  }
  componentDidMount = async () => {
    trackPageView(window.location.pathname + window.location.search)
    await this.fetchPosts()
  }
  fetchPosts = async () => {
    const res = await fetch(`/api/posts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      },
    })
    const { auth, posts, error } = await res.json()
    if(auth) {
      this.setState({
        posts,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  render() {
    if(this.state.posts.length < 1) {
      return (
        <div>
          <Helmet>
            <title>Blog - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Learn how to get a product management offer and ace all your product interviews! Be prepared for product interviews from companies like Google, Facebook, Tesla, and more."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
          <BlogLoader />
        </div>
      )
    }
    return (
      <div className = 'blog'>
        <Helmet>
          <title>Blog - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Learn how to get a product management offer and ace all your product interviews! Be prepared for product interviews from companies like Google, Facebook, Tesla, and more."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
        <div className = 'blog-posts'>
          {
            this.state.posts.map(post => {
              return (
                <BlogPreview
                  slug = {post.slug}
                  featureImage = {post.featureImage}
                  title = {post.title}
                  post = {post.post}
                />
              )
            })
          }
          <BlogCTA />
        </div>
      </div>
    )
  }
}
