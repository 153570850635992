import React, { PureComponent } from 'react'

import '@/_modals.scss'

export default class ErrorModal extends PureComponent {
  render() {
    if (!this.props.show) {
      return null
    }
    return (
      <div className = 'modal-overlay'>
        <div className = 'modal'>
          <h4>Error!</h4>
          <p>{this.props.error}</p>
          <button onClick = {this.props.closeModal}>Close</button>
        </div>
      </div>
    )
  }
}
