import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import Amazon from '@/assets/companies/amazon.svg'
import Facebook from '@/assets/companies/facebook.svg'
import Uber from '@/assets/companies/uber.svg'
import Conor from '@/assets/splash/conor.jpg'
import Gregory from '@/assets/splash/gregory.jpg'
import Janice from '@/assets/splash/janice.jpg'
import './styles.scss'

export default class SuccessStories extends PureComponent {
	state = {
		email: null
	}
	render() {
		return (
			<div className = 'success-stories'>
				<div className = 'success-stories-container'>
					<div className = 'success-story'>
						<img
							src = {Amazon}
							alt = 'Daily Product Prep testimonial'
							className = 'success-logo amazon'
						/>
						<p className = 'testimonial'>"I read Cracking the PM Interview and I think Daily Product Prep is still the best resource. It goes beyond theory and actually helps you learn to execute on product interviews. I was never able to pass the interview until now!"</p>
						<div className = 'success-user'>
							<img
								src = {Conor}
								alt = 'Daily Product Prep testimonial'
								className = 'success-user-avatar'
							/>
							<div className = 'success-user-info'>
								<p className = 'user-info'>Conor</p>
								<p className = 'user-info'>Associate Product Manager</p>
							</div>
						</div>
					</div>
					<div className = 'success-story'>
						<img
							src = {Facebook}
							alt = 'Daily Product Prep testimonial'
							className = 'success-logo facebook'
						/>
						<p className = 'testimonial'>"Even after signing the FB offer I plan to keep my Daily Product Prep subscription to keep learning. It's much better than all the books out there because it gives you relevant and practical problems to solve."</p>
						<div className = 'success-user'>
							<img
								src = {Gregory}
								alt = 'Daily Product Prep testimonial'
								className = 'success-user-avatar'
							/>
							<div className = 'success-user-info'>
								<p className = 'user-info'>Gregory</p>
								<p className = 'user-info'>Product Manager</p>
							</div>
						</div>
					</div>
					<div className = 'success-story'>
						<img
							src = {Uber}
							alt = 'Daily Product Prep testimonial'
							className = 'success-logo uber'
						/>
						<p className = 'testimonial'>"After my friend recommended DPP I gave it a shot. The questions and solutions helped me prepare for my product manager interview questions - one of the prep questions was literally asked at my onsite."</p>
						<div className = 'success-user'>
							<img
								src = {Janice}
								alt = 'Daily Product Prep testimonial'
								className = 'success-user-avatar'
							/>
							<div className = 'success-user-info'>
								<p className = 'user-info'>Janice</p>
								<p className = 'user-info'>Product Manager</p>
							</div>
						</div>
					</div>
				</div>
				<div className = 'final-cta'>
					<input
						type = 'email'
						placeholder = 'Enter your email...'
						onChange = {(ev) => this.setState({ email: ev.target.value })}
						className = 'final-cta-input'
					/>
					<NavLink to = {{pathname: `/signup`, email: this.state.email}}>
						<button className = 'final-cta-button'>Get started</button>
					</NavLink>
				</div>
			</div>
		)
	}
}