import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import cookieManager from '@/lib/cookieManager'
import { trackPageView, trackEvent } from '@/lib/analytics'

import ErrorModal from '@/components/ErrorModal'
import Loader from '@/components/Loader'

import './styles.scss'

export default class Unsubscribe extends PureComponent {
  state = {
    type: null,
    token: null,
    user: {},
    snoozeOrUnsubscribeLoading: false,
    success: false,
  }
  componentDidMount = async () => {
    trackPageView(window.location.pathname + window.location.search)
    await this.fetchUserByTokenAndSetType()
  }
  fetchUserByTokenAndSetType = async () => {
    const params = await new URLSearchParams(this.props.history.location.search)
    const token = params.get('token')
    const type = params.get('type')
    const res = await fetch(`/api/users/token/${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    })
    const { auth, user, error } = await res.json()
    if(auth) {
      this.setState({
        error,
        user,
        token,
        type,
        readableType: type === 'all' ? 'All' : type === 'problemFrequency' ? 'Daily Problems' : type === 'marketingPromotions' ? 'Marketing Promotions' : type === 'featureUpdates' ? 'Feature Updates' : type === 'commentReplies' ? 'Comment Reply' : type === 'solutionReplies' ? 'Solution Reply' : 'Daily Problems',
      })
    } else {
      this.setState({
        error,
      })
    }
  }
  unsubscribeForType = async () => {
    this.setState({
      snoozeOrUnsubscribeLoading: true
    })
    const res = await fetch(`/api/users/unsubscribe/${this.state.token}/${this.state.type}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    })
    const { auth, error } = await res.json()
    if(auth) {
      const referrer = await cookieManager.getReferrer()
      trackEvent({
        category: 'Acquisition',
        action: `Unsubscribed from ${this.state.type} type of emails`,
        label: referrer,
        nonInteraction: false,
      })
      this.setState({
        success: true,
        error,
        snoozeOrUnsubscribeLoading: false,
      })
      await this.props.fetchUser()
      setTimeout(() => {
        this.props.history.push('/settings/email-preferences')
      }, 1000)
    } else {
      this.setState({
        success: false,
        error,
        snoozeOrUnsubscribeLoading: false,
      })
    }
  }
  unsubscribe = async () => {
    this.setState({
      snoozeOrUnsubscribeLoading: true
    })
    const res = await fetch(`/api/users/unsubscribe/${this.state.token}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    })
    const { auth, error } = await res.json()
    if(auth) {
      const referrer = await cookieManager.getReferrer()
      trackEvent({
        category: 'Acquisition',
        action: `Unsubscribed from all emails`,
        label: referrer,
        nonInteraction: false,
      })
      this.setState({
        success: true,
        error,
        snoozeOrUnsubscribeLoading: false,
      })
      await this.props.fetchUser()
      setTimeout(() => {
        this.props.history.push('/settings/email-preferences')
      }, 1000)
    } else {
      this.setState({
        success: false,
        error,
        snoozeOrUnsubscribeLoading: false,
      })
    }
  }
  snooze = async (snoozeFor) => {
    this.setState({
      snoozeOrUnsubscribeLoading: true
    })
    const res = await fetch(`/api/users/snooze/${this.state.token}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      },
      body: JSON.stringify({
        snoozeFor,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      const referrer = await cookieManager.getReferrer()
      trackEvent({
        category: 'Acquisition',
        action: `Snoozed all emails for ${snoozeFor}`,
        label: referrer,
        nonInteraction: false,
      })
      this.setState({
        success: true,
        error,
        snoozeOrUnsubscribeLoading: false
      })
      setTimeout(() => {
        this.props.history.push('/')
      }, 1000)
    } else {
      this.setState({
        success: false,
        error,
        snoozeOrUnsubscribeLoading: false
      })
    }
  }
  closeModal = () => {
    this.setState({
      error: null,
    })
  }
  render() {
    return (
      <div className = 'unsubscribe'>
        <Helmet>
          <title>Interview Prep for Product Jobs - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
        <ErrorModal
          show = {this.state.error && this.state.user.subscribed}
          error = {this.state.error}
          closeModal = {this.closeModal}
        />
        <div>
        {
          this.state.user.email ? (
            <div className = 'unsubscribe-container' >
              <h1 className = 'thin'>Changing preferences for: <span className = 'color-primary'>{this.state.user.email}</span></h1>
                <div className = 'margin-bottom'>
                  You'll no longer receive {this.state.readableType === 'All' ? 'any' : this.state.readableType} emails
                </div>
              {
                this.state.success ? (
                  <div className = 'unsubscribe-options'>
                    <h2>Success!</h2>
                    <span>Redirecting...</span>
                  </div>
                ) : this.state.snoozeOrUnsubscribeLoading ? <Loader /> : this.state.type === 'all' ? (
                  <div className = 'unsubscribe-options'>
                    <button className = 'leave-button' onClick = {() => this.snooze('1 week')}>Snooze for a week</button>
                    <button className = 'leave-button' onClick = {() => this.snooze('1 month')}>Snooze for 1 month</button>
                    <button className = 'leave-button' onClick = {() => this.snooze('3 months')}>Snooze for 3 months</button>
                    <button className = 'leave-button' onClick = {() => this.snooze('6 months')}>Snooze for 6 months</button>
                    <button className = 'leave-button' onClick = {() => this.unsubscribe()}>Unsubscribe</button>
                    <span>or</span>
                    <NavLink to = {`/`}>
                      <button className = 'stay-button'>Nevermind, stay subscribed</button>
                    </NavLink>
                  </div>
                ) : (
                  <div className = 'unsubscribe-options'>
                    <button className = 'leave-button' onClick = {() => this.unsubscribeForType()}>Unsubscribe</button>
                    <span>or</span>
                    <NavLink to = {`/`}>
                      <button className = 'stay-button'>Nevermind, stay subscribed</button>
                    </NavLink>
                  </div>
                )
              }
            </div>
          ) : (
            <div className = 'text-align-center padding'>
              <div>
                <h1>Sorry, we couldn't find the account you're looking to unsubscribe.</h1>
              </div>
              <div>
                <NavLink to = '/'>
                  <button>Go back</button>
                </NavLink>
              </div>
            </div>
          )
        }
        </div>
      </div>
    )
  }
}
