import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { STORAGE_KEY } from '@/lib/constants'
import cookieManager from '@/lib/cookieManager'
import { trackPageView, trackEvent } from '@/lib/analytics'

import SettingsSidebar from '@/components/SettingsSidebar'

import CheckmarkWhite from '@/assets/iconography/checkmarkWhite.svg'
import './styles.scss'

export default class EmailPreferencesSettings extends PureComponent {
	state = {
		problemFrequency: null,
		solutionReplies: false,
		commentReplies: false,
		featureUpdates: false,
		marketingPromotions: false,
    updating: false,
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		if(this.props.user) {
			await this.setInitialUser()
		}
	}
	setInitialUser = () => {
		this.setState({
	    problemFrequency: this.props.user.emailPreferences ? this.props.user.emailPreferences.problemFrequency : null,
	    solutionReplies: this.props.user.emailPreferences ? this.props.user.emailPreferences.solutionReplies : false,
	    commentReplies: this.props.user.emailPreferences ? this.props.user.emailPreferences.commentReplies : false,
	    featureUpdates: this.props.user.emailPreferences ? this.props.user.emailPreferences.featureUpdates : false,
	    marketingPromotions: this.props.user.emailPreferences ? this.props.user.emailPreferences.marketingPromotions : false,
		})
	}
	updateEmailPreferences = async () => {
		this.setState({
			updating: true,
		})
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/users/email-preferences`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        problemFrequency: this.state.problemFrequency,
        solutionReplies: this.state.solutionReplies,
        commentReplies: this.state.commentReplies,
        featureUpdates: this.state.featureUpdates,
        marketingPromotions: this.state.marketingPromotions,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
    	if(this.state.problemFrequency !== 'off' && this.props.user.emailPreferences && this.props.user.emailPreferences.problemFrequency === 'off') {
    		const referrer = await cookieManager.getReferrer()
	      trackEvent({
	        category: 'Acquisition',
	        action: `User resubscribed to ${this.state.problemFrequency} problem emails`,
	        label: referrer,
	        nonInteraction: false,
	      })
    	}
    	await this.props.fetchUser()
      this.setState({
        success: true,
        updating: false,
        error,
      })
      setTimeout(() => {
    		this.setState({
	    		success: false,
	    	})
    	}, 1500)
    } else {
      this.setState({
        updating: false,
        error,
      })
    }
	}
	render() {
		if(!this.props.user) {
			return (
				<div className = 'profile-not-found'>
					<Helmet>
	          <title>Daily Product Prep</title>
	          <meta
	            name = 'description'
	            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
	          />
	          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:type" content="image/png" />
	          <meta property="og:image:width" content="1200" />
	          <meta property="og:image:height" content="630" />
	          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
	        </Helmet>
					<h1>You're not logged in.</h1>
					<NavLink to = {`/login`}>
						<button>Login...</button>
					</NavLink>
				</div>
			)
		}
		return (
			<div className = 'profile padding-top'>
				<Helmet>
          <title>Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<div className = 'profile-grid'>
					<SettingsSidebar
						route = {`Email preferences`}
					/>
					<div className = 'settings-main'>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Problem email frequency</span>
	            <span className = 'input-title thin'>How often you'll receive product management interview problems in your inbox.</span>
	            <div className = 'settings-button-row'>
		            <button className = {this.state.problemFrequency === 'daily' ? null : 'unselected'} onClick = {() => this.setState({ problemFrequency: 'daily' })}>Daily</button>
		            <button className = {this.state.problemFrequency === 'weekly' ? null : 'unselected'} onClick = {() => this.setState({ problemFrequency: 'weekly' })}>Weekly</button>
		            <button className = {this.state.problemFrequency === 'off' ? null : 'unselected'} onClick = {() => this.setState({ problemFrequency: 'off' })}>Off</button>
	            </div>
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Comments on your solutions</span>
	            <div className = 'settings-button-row'>
		            <button className = {this.state.solutionReplies === true ? null : 'unselected'} onClick = {() => this.setState({ solutionReplies: true })}>On</button>
		            <button className = {this.state.solutionReplies === false ? null : 'unselected'} onClick = {() => this.setState({ solutionReplies: false })}>Off</button>
	            </div>
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Replies to your comments</span>
	            <div className = 'settings-button-row'>
		            <button className = {this.state.commentReplies === true ? null : 'unselected'} onClick = {() => this.setState({ commentReplies: true })}>On</button>
		            <button className = {this.state.commentReplies === false ? null : 'unselected'} onClick = {() => this.setState({ commentReplies: false })}>Off</button>
	            </div>
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Feature updates</span>
	            <div className = 'settings-button-row'>
		            <button className = {this.state.featureUpdates === true ? null : 'unselected'} onClick = {() => this.setState({ featureUpdates: true })}>On</button>
		            <button className = {this.state.featureUpdates === false ? null : 'unselected'} onClick = {() => this.setState({ featureUpdates: false })}>Off</button>
	            </div>
            </div>
            <div className = 'settings-input-item'>
	            <span className = 'input-title'>Marketing promotions</span>
	            <div className = 'settings-button-row'>
		            <button className = {this.state.marketingPromotions === true ? null : 'unselected'} onClick = {() => this.setState({ marketingPromotions: true })}>On</button>
		            <button className = {this.state.marketingPromotions === false ? null : 'unselected'} onClick = {() => this.setState({ marketingPromotions: false })}>Off</button>
	            </div>
            </div>
            <div className = 'settings-input-item'>
            	<span className = 'error-text'>{this.state.error}</span>
            	{
            		this.state.success ? (
            			<button className = 'settings-success' disabled>
            				<img
            					src = {CheckmarkWhite}
            					alt = 'Daily Product Prep'
            				/>
            				<span>Saved</span>
            			</button>
            		) : this.state.updating ? (
            			<button className = 'disabled-button' disabled>
            				<span>Saving...</span>
            			</button>
            		) : this.props.user.subscribed ? (
            			<button onClick = {() => this.updateEmailPreferences()}>
          					<span>Save</span>
          				</button>
          			) : (
          				<button onClick = {() => this.updateEmailPreferences()}>
          					<span>Resubscribe</span>
          				</button>
          			)
            	}
            	<NavLink className = 'email-preferences-unsubscribe' to = {`/unsubscribe?token=${this.props.user.verificationToken}&type=all`}>Unsubscribe from all</NavLink>
            </div>
					</div>
				</div>
			</div>
		)
	}
}