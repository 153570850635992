import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

import { STORAGE_KEY } from '@/lib/constants'
import { trackPageView, trackEvent } from '@/lib/analytics'

import ProblemsFilterRow from '@/components/ProblemsFilterRow'
import ProblemsList from '@/components/ProblemsList'

import './styles.scss'

export default class Problems extends PureComponent {
	state = {
		fetching: true,
		problems: [],
		filteredProblems: [],
		order: 'Popularity',
		showOrder: false,
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		await this.fetchProblems()
		this.setState({
			fetching: false
		})
	}
	fetchProblems = async () => {
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
		const res = await fetch(`/api/problems`, {
	      method: 'GET',
	      headers: {
	        'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
	      },
	    })
	    const { auth, problems, error } = await res.json()
	    if(auth) {
	    	this.setState({
	    		problems,
	    		filteredProblems: problems,
	    	})
	    } else {
	    	this.setState({
	    		error
	    	})
	    }
	}
	showOrderDropdown = () => {
		this.setState({
			showOrder: this.state.showOrder ? false : true,
			showDifficulty: false,
		})
	}
	setOrder = async (order) => {
		this.setState({
			order,
			showOrder: false,
		})
		await this.onOrder()
	}
	onOrder = async () => {
		let newProblems = await this.state.filteredProblems
		this.setState({
			fetching: true,
			filteredProblems: []
		})
		if (this.state.order === 'Most solutions') {
			await newProblems.sort((a, b) => {
				return (a.numSolutions===null)-(b.numSolutions===null) || -(a.numSolutions>b.numSolutions)||+(a.numSolutions<b.numSolutions)
			})
		} else if (this.state.order === 'Most activity') {
			await newProblems.sort((a, b) => {
				return (a.numComments===null)-(b.numComments===null) || -(a.numComments>b.numComments)||+(a.numComments<b.numComments)
			})
		} else {
			await newProblems.sort((a, b) => {
				return b.numPraises - a.numPraises
			})
		}
		await this.setState({
			fetching: false,
			filteredProblems: newProblems,
		})
	}
	praiseProblem = async (problem) => {
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
		const res = await fetch (`/api/problems/praise`, {
      method: 'POST',
      headers: {
      	'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      },
      body: JSON.stringify({
        problemId: problem.id,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
    	trackEvent({
    		category: 'Activation',
    		action: 'Praised a problem',
    		label: this.props.user.username,
    		nonInteraction: false,
    	})
    } else {
      this.setState({
        error,
      })
    }
	}
	render() {
		return (
			<div className = 'problems'>
				<Helmet>
          <title>Interview Prep for Product Jobs - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<div className = 'problems-list'>
					<h1>Problems</h1>
					<ProblemsFilterRow
						order = {this.state.order}
						showOrder = {this.state.showOrder}
						showOrderDropdown = {this.showOrderDropdown}
						setOrder = {this.setOrder}
					/>
					<ProblemsList
						fetching = {this.state.fetching}
						problems = {this.state.filteredProblems}
						praiseProblem = {this.praiseProblem}
					/>
					<div className = 'problems-pagination'>
					</div>
				</div>
			</div>
		)
	}
}