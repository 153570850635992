import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import marked from 'marked'

import { STORAGE_KEY } from '@/lib/constants'
import { trackEvent } from '@/lib/analytics'

import PraiseClear from '@/assets/iconography/praiseClear.svg'
import PraiseDark from '@/assets/iconography/praiseDark.svg'
import Comment from '@/assets/iconography/comment.svg'

import './styles.scss'

export default class CommentCard extends PureComponent {
	state = {
		comment: '',
		submitting: false,
		userPraiseCount: 0,
		commentPraiseCount: 0,
	}
	componentDidMount = () => {
		this.setState({
			userPraiseCount: this.props.comment.commentPraises.filter(praise => praise.userId === this.props.user.id).length,
			commentPraiseCount: this.props.comment.commentPraises.length,
		})
	}
	createMarkup = (content) => {
    const html = marked(content)
    return { __html: html }
  }
  reply = async () => {
  	this.setState({
  		error: null,
  		submitting: true,
  	})
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch (`/api/comments`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      },
      body: JSON.stringify({
        problemId: this.props.problem.id,
        comment: this.state.comment,
        parentId: this.props.comment.id,
      })
    })
    const { auth, commentId, error } = await res.json()
    if(auth) {
    	trackEvent({
    		category: 'Activation',
    		action: 'Wrote a comment',
    		label: this.props.user.email,
    		nonInteraction: false,
    	})
    	this.setState({
    		success: true
    	})
    	setTimeout(() => {
    		this.props.fetchComments(commentId)
    		this.setState({
    			error,
    			submitting: false,
    			replying: false,
    			comment: '',
    		})
    	}, 1500)
    } else {
      this.setState({
        error,
        submitting: false,
      })
    }
  }
	praiseComment = async (ev, comment) => {
		ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch (`/api/comments/praise`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      },
      body: JSON.stringify({
        commentId: comment.id,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
    	trackEvent({
    		category: 'Activation',
    		action: 'Praised a comment',
    		label: this.props.user.username,
    		nonInteraction: false,
    	})
    	this.setState({
    		userPraiseCount: parseInt(this.state.userPraiseCount) + 1,
    		commentPraiseCount: parseInt(this.state.commentPraiseCount) + 1,
    	})
    } else {
			console.log(error)
    }
  }
	render() {
		if(this.props.comment.status === 'inactive') {
			return null
		}
		return (
			<div className = 'comment' id = {this.props.comment.id}>
				<div className = {this.props.permaId === this.props.comment.id ? 'permalink comment-card' : this.state.replying ? 'comment-card comment-highlight' : 'comment-card'}>
					<div className = 'top-row'>
						<img
							src = {this.props.comment.user.avatarURL}
							alt = 'Daily Product Prep avatar'
							className = 'card-avatar'
						/>
						<span className = 'small'><NavLink to = {`/@/${this.props.comment.user.username}`} className = 'color-black bold username'>{this.props.comment.user.firstName} {this.props.comment.user.lastName}</NavLink><span className = 'color-grey thin'> • {moment(this.props.comment.createdAt).fromNow()}</span></span>
					</div>
					<div className = 'comment-markup' dangerouslySetInnerHTML = {this.createMarkup(this.props.comment.text)} />
					<div className = 'footer-row'>
						<span className = {this.state.userPraiseCount > 0 ? 'color-primary xsmall bold' : 'xsmall bold'}>{this.state.commentPraiseCount}</span>
						<div onClick = {(ev) => this.praiseComment(ev, this.props.comment)}>
							{
								this.state.userPraiseCount >= 10 ? (
									<img
										src = {PraiseClear}
										alt = 'Daily Product Prep'
										className = 'praise'
									/>
								) : (
									<img
										src = {PraiseDark}
										alt = 'Daily Product Prep'
										className = 'praise'
									/>
								)
							}
						</div>
						<div className = 'reply' onClick = {() => this.setState({ replying: this.state.replying ? false : true })}>
							<img
								src = {Comment}
								alt = 'Daily Product Prep'
								className = 'reply-icon'
							/>
							<span className = 'xsmall thin bold'>Reply</span>
						</div>
						<div className = 'footer-permalink' onClick = {() => this.props.toPermalink(this.props.comment.id)}>
							<span className = 'xsmall thin'>Permalink</span>
						</div>
					</div>
					{
						this.state.replying ? (
							<div className = 'composer-comments'>
								<textarea
			            className = 'composer-comments-textarea'
			            placeholder = 'How would you answer this interview question?'
			            rows = {5}
			            value = {this.state.comment}
			            onChange = {(ev) => this.setState({ comment: ev.target.value })}
			          />
			          <p className = 'error-text'>{this.state.error}</p>
			          {
			            this.state.submitting ? (
			              <button className = 'composer-comments-btn disabled-button' disabled>Replying...</button>
			            ) : <button className = 'composer-comments-btn' onClick = {() => this.reply()}>Reply</button>
			          }
			         </div>
						) : null
					}
				</div>
				<div className = 'comment-children'>
					{
						this.props.comment.comments.map(reply => {
							return (
								<CommentCard
	      					comment = {reply}
	      					problem = {this.props.problem}
	      					fetchComments = {this.props.fetchComments}
	      					user = {this.props.user}
	      					permaId = {this.props.permaId}
	      					toPermalink = {this.props.toPermalink}
								/>
							)
						})
					}
				</div>
			</div>
		)
	}
}