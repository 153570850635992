import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { Helmet } from 'react-helmet'

import { STORAGE_KEY } from '@/lib/constants'
import { trackPageView } from '@/lib/analytics'

import Loader from '@/components/Loader'
import ProblemCard from '@/components/ProblemCard'

import Streak from '@/assets/iconography/streak.svg'
import SolvedToday from '@/assets/iconography/solvedToday.svg'
import CreatedAt from '@/assets/iconography/createdAt.svg'
import Ranking from '@/assets/iconography/ranking.svg'
import PraiseDark from '@/assets/iconography/praiseDark.svg'
import Solution from '@/assets/iconography/solution.svg'
import Comment from '@/assets/iconography/comment.svg'
import LinkedIn from '@/assets/onboarding/linkedin.svg'
import './styles.scss'

export default class Profile extends PureComponent {
	state = {
		fetching: true,
		isSameUser: false,
		user: null,
		solutions: [],
		tab: 'overview',
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		let params = await new URLSearchParams(this.props.history.location.search)
   	const tab = await params.get('tab') ? params.get('tab') : 'overview'
   	await this.fetchPublicProfile()
		await this.setTab(tab)
		await this.isSameUser()
		await this.setState({
			fetching: false,
		})
	}
	setTab = async (tab) => {
   	this.setState({
 			tab,
 		})
	}
	isSameUser = () => {
		const username = this.props.match.params.username
		if(this.props.user && this.props.user.username === username) {
			this.setState({
				isSameUser: true,
				username,
			})
		} else {
			this.setState({
				isSameUser: false,
				username,
			})
		}
	}
	fetchPublicProfile = async () => {
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
		const username = this.props.match.params.username
		const res = await fetch(`/api/users/public-profile/${username}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { auth ,user, error } = await res.json()
    if(auth) {
    	this.setState({
	      user,
	      solutions: user.solutions,
	      error,
	    })
    } else {
    	this.setState({
	      user,
	      error,
	    })
    }
	}
	render() {
		if(this.state.fetching) {
			return (
				<div className = 'auth-title'>
					<Helmet>
            <title>Profile - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<Loader />
				</div>
			)
		}
		if(!this.state.user) {
			return (
				<div className = 'profile-not-found'>
					<Helmet>
            <title>Profile - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<h1>Profile not found.</h1>
					<NavLink to = {`/`}>
						<button>Go back</button>
					</NavLink>
				</div>
			)
		}
		return (
			<div className = 'profile'>
				<Helmet>
          <title>Profile - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<div className = 'profile-grid'>
					<div className = 'profile-info'>
						<div className = 'profile-user-info-header'>
							<div className = 'profile-user-avatar'>
								<img
									src = {this.state.user.avatarURL}
									alt = 'Daily Product Prep avatar'
								/>
							</div>
							<div className = 'profile-user-names'>
								<div className = 'profile-user-name'>
									<h2 className = 'real-name'>{this.state.user.firstName} {this.state.user.lastName}</h2>
								</div>
								<div className = 'profile-badge' />
							</div>
						</div>
						{
							this.state.user.about && this.state.user.about.bio ? (
								<div className = 'profile-user-bio'>
									{this.state.user.about.bio}
								</div>
							) : null
						}
						<div className = 'profile-user-info-stats'>
							<div className = 'sidebar-stat'>
								<img
									src = {Streak}
									alt = 'Daily Product Prep stats'
									className = 'sidebar-icon'
								/>
								<span>{this.state.user.currentStreak} day streak</span>
							</div>
							<div className = 'sidebar-stat'>
								<img
									src = {SolvedToday}
									alt = 'Daily Product Prep stats'
									className = 'sidebar-icon'
								/>
								<span>{this.state.user.solvedToday <= 0 ? `No problems` : this.state.user.solvedToday === 1 ? `1 problem` : `${this.state.user.solvedToday} problems`} solved today</span>
							</div>
							<div className = 'sidebar-stat'>
								<img
									src = {CreatedAt}
									alt = 'Daily Product Prep stats'
									className = 'sidebar-icon'
								/>
								<span>Joined {moment(this.state.user.createdAt).fromNow()}</span>
							</div>
							{
								this.state.user.about && this.state.user.about.linkedInURL ? (
									<div className = 'sidebar-stat'>
										<img
											src = {LinkedIn}
											alt = 'Daily Product Prep stats'
											className = 'sidebar-icon'
										/>
										<a href = {`${this.state.user.about.linkedInURL}`} target = '_blank' rel = 'noopener noreferrer' className = 'linkedin'>{this.state.user.firstName} {this.state.user.lastName}</a>
									</div>
								) : null
							}
						</div>
						<div className = 'profile-edit-profile'>
							{
								this.state.isSameUser ? (
									<NavLink to = {`/settings/profile`}>
										<button>Edit profile</button>
									</NavLink>
								) : null
							}
						</div>
						<div>
						</div>
					</div>
					<div className = 'profile-main'>
						<div className = 'profile-tabs'>
							<NavLink to = {`/@/${this.state.username}`} onClick = {() => this.setTab('overview')} className = {this.state.tab === 'overview' ? 'tab active-tab' : 'tab'}>
								<div className = 'tab-text-container'>
									<span>Overview</span>
								</div>
							</NavLink>
							<NavLink to = {`/@/${this.state.username}?tab=solutions`} onClick = {() => this.setTab('solutions')} className = {this.state.tab === 'solutions' ? 'tab active-tab' : 'tab'}>
								<div className = 'tab-text-container'>
									<span>Solutions</span>
									<div className = 'tab-text-solutions-pill'>{this.state.user.numSolutions}</div>
								</div>
							</NavLink>
						</div>
						{
							this.state.tab === 'solutions' ? (
								<div>
									{
										this.state.solutions.map(solution => {
											return (
												<ProblemCard
													location = 'profile'
													solution = {solution}
													route = {this.state.isSameUser ? `/problems/${solution.problem.slug}/${solution.id}` : `/problems/${solution.problem.slug}/${solution.id}`}
												/>
											)
										})
									}
								</div>
							) : (
								<div className = 'overview'>
									<div className = 'overview-core'>
										<div className = 'core-panel'>
											<p className = 'panel-title'>Progress</p>
											<div className = 'panel-grid'>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<span className = 'easy'>Easy</span>
													</div>
													<span>{this.state.user.easySolved}</span>
												</div>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<span className = 'medium'>Medium</span>
													</div>
													<span>{this.state.user.mediumSolved}</span>
												</div>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<span className = 'hard'>Hard</span>
													</div>
													<span>{this.state.user.hardSolved}</span>
												</div>
											</div>
										</div>
										<div className = 'core-panel'>
											<p className = 'panel-title'>Achievements</p>
											<div className = 'panel-grid'>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<img
															src = {Ranking}
															alt = 'Daily Product Prep profile'
														/>
														<span>Ranking</span>
													</div>
													<span>-</span>
												</div>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<img
															src = {PraiseDark}
															alt = 'Daily Product Prep profile'
														/>
														<span>Highest praise</span>
													</div>
													<span>{this.state.user.maxPraise}</span>
												</div>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<img
															src = {Streak}
															alt = 'Daily Product Prep profile'
														/>
														<span>Longest streak</span>
													</div>
													<span>{this.state.user.longestStreak}</span>
												</div>
											</div>
										</div>
										<div className = 'core-panel'>
											<p className = 'panel-title'>Solutions</p>
											<div className = 'panel-grid'>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<img
															src = {Solution}
															alt = 'Daily Product Prep profile'
														/>
														<span>Solutions written</span>
													</div>
													<span>{this.state.user.numSolutions}</span>
												</div>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<img
															src = {PraiseDark}
															alt = 'Daily Product Prep profile'
														/>
														<span>Solution praise received</span>
													</div>
													<span>{this.state.user.numSolutionPraisesReceived}</span>
												</div>
											</div>
										</div>
										<div className = 'core-panel'>
											<p className = 'panel-title'>Reviews</p>
											<div className = 'panel-grid'>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<img
															src = {Comment}
															alt = 'Daily Product Prep profile'
														/>
														<span>Comments written</span>
													</div>
													<span>{this.state.user.numComments}</span>
												</div>
												<div className = 'panel-grid-item'>
													<div className = 'item-title'>
														<img
															src = {PraiseDark}
															alt = 'Daily Product Prep profile'
														/>
														<span>Comment praise received</span>
													</div>
													<span>{this.state.user.numCommentPraisesReceived}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						}
					</div>
				</div>
			</div>
		)
	}
}