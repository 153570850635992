import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

import { trackPageView } from '@/lib/analytics'

import Onboarding01Basics from '@/components/Onboarding01Basics'
import Onboarding02About from '@/components/Onboarding02About'
import Onboarding03EmailPreferences from '@/components/Onboarding03EmailPreferences'

import './styles.scss'

export default class Onboarding extends PureComponent {
  state = {
    progress: 1,
  }
  componentDidMount = async () => {
    trackPageView(window.location.pathname + window.location.search)
    await this.checkProgress()
  }
  checkProgress = async () => {
    await this.props.fetchUser()
    let progress = 1
    if (this.props.user && this.props.user.firstName && this.props.user.lastName && this.props.user.username && this.props.user.password && this.props.user.about && this.props.user.about.status && this.props.user.emailPreferences && this.props.user.emailPreferences.problemFrequency) {
      progress = 4
    } else if (this.props.user && this.props.user.firstName && this.props.user.lastName && this.props.user.username && this.props.user.password && this.props.user.about && this.props.user.about.status) {
      progress = 3
    } else if (this.props.user && this.props.user.firstName && this.props.user.lastName && this.props.user.username && this.props.user.password) {
      progress = 2
    }
    this.setState({
      progress,
    })
  }
  render() {
    return (
      <div className = 'onboarding'>
        <Helmet>
          <title>Welcome - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
        {
          this.state.progress === 1 ? (
            <Onboarding01Basics user = {this.props.user} checkProgress = {this.checkProgress} />
          ) : this.state.progress === 2 ? (
            <Onboarding02About user = {this.props.user} checkProgress = {this.checkProgress} />
          ) : this.state.progress === 3 ? (
            <Onboarding03EmailPreferences user = {this.props.user} checkProgress = {this.checkProgress} />
          ) : null
        }
      </div>
    )
  }
}
