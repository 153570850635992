import React, { PureComponent } from 'react'

import './styles.scss'

export default class ProblemLoader extends PureComponent {
	render() {
		return (
			<div className = 'problem-loader'>
				<div className = 'problem-loader-container'>
					<div className = 'loader-card borderless problem-loader-card'>
						<div className = 'left-container'>
							<div className = 'card-details'>
								<div className = 'bar' />
								<div className = 'pills'>
									<div className = 'pill-bar' />
									<div className = 'pill-bar' />
									<div className = 'pill-bar' />
								</div>
								<div className = 'optional-tags'>
									<div className = 'optional-bar' />
									<div className = 'optional-bar' />
								</div>
							</div>
						</div>
					</div>
					<div className = 'problem-and-input-bar' />
					<div className = 'problem-and-input-bar' />
					<div className = 'submit-bar' />
				</div>
				<div className = 'comment-loader-container'>
					<div className = 'comment-loader-card'>
						<div className = 'top-row'>
							<div className = 'avatar-bar' />
							<div className = 'name-bar'/>
							<div className = 'date-bar'/>
						</div>
						<div className = 'markup-loader-card'>
							<div className = 'full-writeup-bar' />
							<div className = 'quarter-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'quarter-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'half-writeup-bar' />
							<div className = 'half-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'half-writeup-bar' />
							<div className = 'quarter-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'half-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'quarter-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'quarter-writeup-bar' />
							<div className = 'full-writeup-bar' />
							<div className = 'full-writeup-bar' />
						</div>
					</div>
				</div>
			</div>
		)
	}
}