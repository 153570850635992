import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { trackPageView } from '@/lib/analytics'

import Loader from '@/components/Loader'

export default class Verification extends PureComponent {
  state = {
    success: false,
  }
  componentDidMount = async () => {
    trackPageView(window.location.pathname + window.location.search)
    let params = await new URLSearchParams(this.props.history.location.search)
    const verificationToken = params.get('token')
    const res = await fetch(`/api/users/verify/${verificationToken}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    })
    const { auth, token, error } = await res.json()
    if(auth) {
      this.setState({
        success: true,
      })
      if(token) {
        await this.props.login(token)
        setTimeout(() => {
         this.props.history.push('/')
       }, 1500)
      } else {
        setTimeout(() => {
         this.props.history.push('/login')
       }, 1500)
      }
    } else {
      this.setState({
        error,
      })
    }
  }
  render() {
    if(this.state.error) {
      return (
        <div className = 'profile-not-found'>
          <Helmet>
            <title>Interview Prep for Product Jobs - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
          <div className = 'padding text-align-center'>
            <h1 className = 'no-margin'>There was a problem verifying your account.</h1>
            <p className = 'error-text'>{this.state.error}</p>
            <NavLink to = {`/`}>
              <button>Go back</button>
            </NavLink>
          </div>
        </div>
      )
    }
    if(this.state.success) {
      return (
        <div className = 'profile-not-found'>
          <Helmet>
            <title>Interview Prep for Product Jobs - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
          <span className = 'large margin-bottom'>Success!</span>
          <Loader />
          <span className = 'margin-top'>Redirecting...</span>
        </div>
      )
    }
    return (
      <div className = 'profile-not-found'>
        <Helmet>
          <title>Interview Prep for Product Jobs - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
        <span className = 'large margin-bottom'>Verifiying...</span>
        <Loader />
      </div>
    )
  }
}
