import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

import cookieManager from '@/lib/cookieManager'
import { trackPageView, trackEvent } from '@/lib/analytics'

export default class OnboardingComplete extends PureComponent {
  componentDidMount = () => {
    trackPageView(window.location.pathname + window.location.search)
    if(!this.props.user) {
      this.props.history.push('/signup')
    } else {
      const ref = cookieManager.getReferrer()
      trackEvent({
        category: 'Acquisition',
        action: 'Completed onboarding',
        label: ref,
        nonInteraction: false,
      })
    }
  }
  toProblems = async () => {
    await this.props.fetchUser()
    this.props.history.push('/problems')
  }
  render() {
    return (
      <div className = 'onboarding'>
        <Helmet>
          <title>Welcome - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
        <div className = 'column align-items-center'>
          <div className = 'auth-title'>You're all set!</div>
          <div className = 'margin-bottom'>
            <span className = 'text-align-center color-grey'>Your account is fully setup!</span>
          </div>
          <div className = 'margin-bottom focusable column'>
            <button onClick = {() => this.toProblems()}>Finish onboarding</button>
          </div>
        </div>
      </div>
    )
  }
}
