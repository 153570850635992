import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import './styles.scss'

export default class AcceptTermsAndPrivacy extends PureComponent {
	render() {
		return (
			<div className = 'accept-terms-and-privacy'>
				<p className = 'thin small color-grey'>By clicking "{this.props.title}" above, you acknowledge that you have read and understood, and agree to Daily Product Prep's <NavLink to = {`/terms-and-conditions`} className = 'color-grey underline hover'>Terms and Conditions</NavLink> and <NavLink to = {`/privacy-policy`} className = 'color-grey underline hover'>Privacy Policy</NavLink>.</p>
			</div>
		)
	}
}