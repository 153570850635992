import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

import Onboarding from '@/pages/Onboarding'
import Splash from '@/pages/Splash'

export default class Homepage extends PureComponent {
  componentDidMount = async () => {
    await this.setView()
  }
  setView = () => {
    if(this.props.user && !this.props.requiresOnboarding) {
      this.props.history.push('/problems')
    }
  }
  render() {
    if(this.props.user && this.props.requiresOnboarding) {
      return (
        <div>
          <Helmet>
            <title>Interview Prep for Product Jobs - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
          <Onboarding user = {this.props.user} fetchUser = {this.props.fetchUser} setView = {this.setView} />
        </div>
      )
    }
    return (
      <div>
        <Helmet>
          <title>Interview Prep for Product Jobs - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
        <Splash />
      </div>
    )
  }
}
