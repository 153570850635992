import React, { PureComponent } from 'react'
import { NavHashLink as NavLink } from 'react-router-hash-link'

import Checkmark from '@/assets/iconography/checkmark.svg'
import './styles.scss'

export default class FeatureComparisonTable extends PureComponent {
  render() {
    return (
      <div className = 'column mobile-hidden' id = 'features'>
        <div className = 'pricing-subheader'>
          <span>Feature Comparison</span>
        </div>
        <table className = 'pricing-table'>
          <tbody>
            <tr>
              <td className = 'table-title'>Basics</td>
              <td className = 'table-plan-title'>Free</td>
              <td className = 'table-plan-title'>Pro Monthly</td>
              <td className = 'table-plan-title'>Pro Yearly</td>
            </tr>
            <tr>
              <td className = 'table-feature'>Starter problems</td>
              <td className = 'table-benefit'>5</td>
              <td className = 'table-benefit'>20</td>
              <td className = 'table-benefit'>60</td>
            </tr>

            <tr>
              <td className = 'table-title'>Interview prep</td>
              <td className = 'table-plan-title'>Free</td>
              <td className = 'table-plan-title'>Pro Monthly</td>
              <td className = 'table-plan-title'>Pro Yearly</td>
            </tr>
            <tr>
              <td className = 'table-feature'>Daily problems</td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Expert solutions</td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Community solutions</td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Solution feedback</td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Expert mock interviews</td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>

            <tr>
              <td className = 'table-title'>Personal branding</td>
              <td className = 'table-plan-title'>Free</td>
              <td className = 'table-plan-title'>Pro Monthly</td>
              <td className = 'table-plan-title'>Pro Yearly</td>
            </tr>
            <tr>
              <td className = 'table-feature'>Viewable profile</td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Contest submissions <div className = 'pricing-coming-soon'>Coming soon</div></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Commenting</td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>

            <tr>
              <td className = 'table-title'>Other</td>
              <td className = 'table-plan-title'>Free</td>
              <td className = 'table-plan-title'>Pro Monthly</td>
              <td className = 'table-plan-title'>Pro Yearly</td>
            </tr>
            <tr>
              <td className = 'table-feature'>Job referrals <div className = 'pricing-coming-soon'>Coming soon</div></td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Priority support</td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Monthly discount</td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Affiliate perks</td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
            <tr>
              <td className = 'table-feature'>Early feature access</td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'></td>
              <td className = 'table-benefit'>
                <img
                  src = {Checkmark}
                  alt = 'Daily Product Prep thin checkmark'
                  className = 'pricing-checkmark'
                />
              </td>
            </tr>
          </tbody>
          <div className = 'pricing-cta'>
            <NavLink smooth to = '/pricing#plans'>
              <button>Get started</button>
            </NavLink>
          </div>
        </table>
      </div>
    )
  }
}