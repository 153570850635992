import React, { PureComponent } from 'react'

import Loader from '@/components/Loader'

import './styles.scss'

export default class ProcessingPayment extends PureComponent {
  render() {
    if(!this.props.show) {
      return null
    }
    if(this.props.success) {
      return (
        <div className = 'processing-payment'>
          <span className = 'large margin-bottom'>Success!</span>
          <Loader />
          <span className = 'margin-top'>Redirecting...</span>
        </div>
      )
    }
    return (
      <div className = 'processing-payment'>
        <span className = 'large margin-bottom'>Processing...</span>
        <Loader />
      </div>
    )
  }
}
