import React, { PureComponent } from 'react'
import { StripeProvider } from 'react-stripe-elements'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { humanizePrice } from '@/lib/humanize'
import { trackPageView, trackEvent } from '@/lib/analytics'
import cookieManager from '@/lib/cookieManager'

import ProcessingPayment from '@/components/ProcessingPayment'
import ElementsCheckout from '@/components/ElementsCheckout'
import Loader from '@/components/Loader'

import BackArrow from '@/assets/iconography/backArrow.svg'
import Discount from '@/assets/iconography/discount.svg'
import Cross from '@/assets/iconography/cross.svg'
import Logo from '@/assets/logos/logo.svg'
import './styles.scss'

export default class Checkout extends PureComponent {
  state = {
    code: null,
    coupon: null,
    loading: true,
    yearlyPrice: null,
    monthlyPrice: null,
    emailWarning: null,
    validTLDs: ['.com','.ca','.edu','.org','.co','.io']
  }
  componentDidMount = async () => {
    trackPageView(window.location.pathname + window.location.search)
    let params = await new URLSearchParams(this.props.history.location.search)
    const code = params.get('code')
    const plan = params.get('plan') ? params.get('plan') : 'monthly'
    if (code) {
      await this.checkCouponCode(code)
    } else {
      await this.setPrice()
    }
    this.setState({
      code,
      plan,
      loading: false,
    })
  }
  checkCouponCode = async (code) => {
    const res = await fetch(`/api/subscriptions/coupons/${code}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    })
    const { auth, coupon, error } = await res.json()
    if(auth) {
      this.setState({
        coupon,
        couponError: error,
      })
      await this.setPrice()
    } else {
      this.setState({
        couponError: error,
      })
    }
  }
  setPrice = () => {
    let yearlyPrice = 12000
    let monthlyPrice = 1500
    if(this.state.coupon) {
      if(this.state.coupon.subscriptionType === 'both' || this.state.coupon.subscriptionType === 'monthly') {
        monthlyPrice = ((monthlyPrice - this.state.coupon.amountOffInCents) * ((100 - this.state.coupon.percentOff)/100)).toFixed(2)
      }
      if(this.state.coupon.subscriptionType === 'both' || this.state.coupon.subscriptionType === 'yearly') {
        yearlyPrice = ((yearlyPrice - this.state.coupon.amountOffInCents) * ((100 - this.state.coupon.percentOff)/100)).toFixed(2)
      }
    }
    this.setState({
      yearlyPrice,
      monthlyPrice,
    })
  }
  setEmail = (email) => {
    this.setState({
      email,
    })
    let TLDs = this.state.validTLDs
    let showWarning = true
    for(let ending of TLDs) {
      if(email.indexOf(ending) === email.length - ending.length) {
        showWarning = false
      }
    }
    this.setState({
      emailWarning: showWarning ? 'This email looks incorrect, double check before pressing checkout!' : null,
    })
  }
  clearCoupon = async () => {
    await this.setState({
      coupon: null,
      code: null,
      addPromo: false,
    })
    await this.setPrice()
  }
  onCheckout = async (paymentToken, recaptchaValue) => {
    this.setState({
      processPayment: true,
    })
    if(paymentToken.error) {
      this.setState({
        error: paymentToken.error.message,
        processPayment: false,
      })
    } else {
      const visitorId = cookieManager.getVisitorId()
      const referrer = cookieManager.getReferrer()
      const cookies = {
        visitorId,
        referrer,
      }
      const coupon = this.state.coupon ? this.state.coupon.code : null
      const params = {
        email: this.props.user ? this.props.user.email : this.state.email,
        paymentToken: paymentToken.token.id,
        plan: this.state.plan,
        recaptchaValue,
        coupon,
        cookies,
      }
      const res = await fetch('/api/subscriptions/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin'
        },
        body: JSON.stringify(params)
      })
      const { auth, token, error } = await res.json()
      if(auth) {
        trackEvent({
          category: 'Revenue',
          action: `Purchased a ${this.state.plan} subscription`,
          label: referrer,
          value: this.state.plan === 'monthly' ? this.state.monthlyPrice / 100 : this.state.yearlyPrice / 100,
          nonInteraction: false,
        })
        await this.setState({
          success: true
        })
        if(token) {
          await this.props.login(token)
          setTimeout(() => {
            this.props.history.push('/')
          }, 1000)
        } else {
          setTimeout(() => {
            this.props.history.push('/login')
          }, 1000)
        }
      } else {
        this.setState({
          error,
          processPayment: false,
        })
      }
    }
  }
  render() {
    const stripeKey = process.env.NODE_ENV === 'production' ? 'pk_live_ONMbjDDucrQCXB5D4ukTTUVY00udpDOCuK' : 'pk_test_jXVzPY1gJsxeePCC2FGoFTf900diiczt8N'
    if(this.state.loading) {
      return (
        <div className = 'auth-title'>
          <Helmet>
            <title>Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
          <Loader />
        </div>
      )
    }
    return (
      <StripeProvider apiKey = {stripeKey}>
        <div className = 'onboarding'>
          <Helmet>
            <title>Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
          <ProcessingPayment
            show = {this.state.processPayment}
            success = {this.state.success}
          />
          <div className = 'checkout-container'>
            <div className = 'checkout-panel'>
              <div className = 'checkout-navigator'>
                <NavLink to = {`/pricing`}>
                  <img
                    src = {BackArrow}
                    alt = 'Daily Product Prep'
                    className = 'checkout-back-arrow'
                  />
                </NavLink>
                <div className = 'checkout-logo-container'>
                  <img
                    src = {Logo}
                    alt = 'Daily Product Prep'
                    className = 'checkout-logo'
                  />
                </div>
                <h3 className = 'checkout-wordmark'>Daily Product Prep</h3>
              </div>
              {
                this.state.plan === 'monthly' ? (
                  <div className = 'checkout-product-summary'>
                    <span className = 'checkout-plan-name'>Subscribe to Pro Monthly</span>
                    <div className = 'plan-price-list'>
                      <span className = 'plan-price'>{this.state.coupon && (this.state.coupon.subscriptionType === 'both' || this.state.coupon.subscriptionType === 'monthly') ? <span className = 'strikethrough small color-primary'>$15</span> : null} ${humanizePrice(this.state.monthlyPrice / 100)}</span>
                      <div className = 'plan-price-units'>
                        <span className = 'plan-price-unit'>{this.state.coupon && (this.state.coupon.subscriptionType === 'both' || this.state.coupon.subscriptionType === 'monthly') && this.state.coupon?.duration === 'once' ? `first month, then $15` : `per month`}</span>
                      </div>
                    </div>
                  </div>
                ) : this.state.plan === 'yearly' ? (
                  <div className = 'checkout-product-summary'>
                    <span className = 'checkout-plan-name'>Pro Yearly</span>
                    <div className = 'plan-price-list'>
                      <span className = 'plan-price'>{this.state.coupon && (this.state.coupon.subscriptionType === 'both' || this.state.coupon.subscriptionType === 'yearly') ? <span className = 'strikethrough small color-primary'>$120</span> : null} ${humanizePrice(this.state.yearlyPrice / 100)}</span>
                      <div className = 'plan-price-units'>
                        <span className = 'plan-price-unit'>{this.state.coupon && (this.state.coupon.subscriptionType === 'both' || this.state.coupon.subscriptionType === 'yearly') && this.state.coupon?.duration === 'once' ? `first year, then $120` : `per year`}</span>
                      </div>
                    </div>
                  </div>
                ) : null
              }
              <div className = 'checkout-promo'>
                {
                  this.state.coupon ? (
                    <button className = 'subtle-button add-promo-button' onClick = {() => this.clearCoupon()}>
                      <div className = 'row align-items-center'>
                        <img
                          src = {Discount}
                          alt = 'Daily Product Prep discount code'
                          className = 'checkout-discount-tag'
                        />
                        <span>{this.state.code}</span>
                        <img
                          src = {Cross}
                          alt = 'Daily Product Prep cross'
                          className = 'checkout-cross'
                        />
                      </div>
                    </button>
                  ) : this.state.addPromo ? (
                    <div>
                      <div>
                        <input
                          placeholder = 'Add promotion code'
                          className = 'promo-input'
                          onChange = {(ev) => this.setState({ code: ev.target.value, couponError: null })}
                          value = {this.state.code}
                        />
                        <button className = 'subtle-button add-promo-button' onClick = {() => this.checkCouponCode(this.state.code)}>Apply</button>
                      </div>
                      <span className = 'color-error xsmall'>{this.state.couponError}</span>
                    </div>
                  ) : <button className = 'subtle-button add-promo-button' onClick = {() => this.setState({ addPromo: true })}>Add promotion code</button>
                }
              </div>
            </div>
            <div className = 'checkout-panel'>
              <ElementsCheckout
                user = {this.props.user}
                error = {this.state.error}
                setEmail = {this.setEmail}
                emailWarning = {this.state.emailWarning}
                onCheckout = {this.onCheckout}
              />
            </div>
          </div>
        </div>
      </StripeProvider>
    )
  }
}
