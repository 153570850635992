import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import './styles.scss'

export default class SettingsSidebar extends PureComponent {
	render() {
		return (
			<div className = 'settings-sidebar'>
				<NavLink to = {`/settings/profile`} className = {this.props.route === 'Profile' ? 'active-tab tab' : 'tab'}>
					<p>Profile</p>
				</NavLink>
				<NavLink to = {`/settings/security`} className = {this.props.route === 'Security' ? 'active-tab tab' : 'tab'}>
					<p>Security</p>
				</NavLink>
				<NavLink to = {`/settings/billing`} className = {this.props.route === 'Billing' ? 'active-tab tab' : 'tab'}>
					<p>Billing & plan</p>
				</NavLink>
				<NavLink to = {`/settings/email-preferences`} className = {this.props.route === 'Email preferences' ? 'active-tab tab' : 'tab'}>
					<p>Email preferences</p>
				</NavLink>
			</div>
		)
	}
}