import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import marked from 'marked'
import { Helmet } from 'react-helmet'

import MdListBox from 'react-ionicons/lib/MdListBox'
import IosUnlock from 'react-ionicons/lib/IosUnlock'

import { STORAGE_KEY } from '@/lib/constants'
import { trackPageView, trackEvent } from '@/lib/analytics'

import ProblemLoader from '@/components/ProblemLoader'
import ProblemCard from '@/components/ProblemCard'
import CommentCard from '@/components/CommentCard'

import BlurredSolution from '@/assets/images/blurred-solution.png'

import './styles.scss'

export default class Problem extends PureComponent {
	state = {
		loading: true,
		comment: '',
		problem: null,
		showBlurredSolution: false,
		permaId: this.props.match.params.permaId,
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		await this.fetchProblem()
		await this.fetchComments()
		this.setState({
			loading: false
		})
		await this.scrollToPermalink()
	}
  fetchProblem = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const slug = this.props.match.params.slug
    const res = await fetch(`/api/problems/${slug}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
    })
    const { auth, problem, error } = await res.json()
    if(auth) {
      this.setState({
        problem,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  toPermalink = (permaId) => {
  	this.setState({
  		permaId
  	})
  	document.getElementById(permaId).scrollIntoView({ behavior: 'smooth' })
  	this.props.history.push(`/problems/${this.state.problem.slug}/${permaId}`)
  }
  scrollToPermalink = () => {
  	if(this.state.permaId && document.getElementById(this.state.permaId)) {
  		document.getElementById(this.state.permaId).scrollIntoView({ behavior: 'smooth' })
  		this.props.history.push(`/problems/${this.state.problem.slug}/${this.state.permaId}`)
  	}
  } 
  fetchComments = async (commentId) => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const slug = this.props.match.params.slug
    const res = await fetch(`/api/comments/${slug}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
    })
    const { auth, comments, error } = await res.json()
    if(auth) {
      let orderedComments = await this.order(comments)
      this.setState({
        comments: orderedComments,
      })
      if(commentId) {
      	this.toPermalink(commentId)
      }
    } else {
      this.setState({
        comments,
        error,
      })
    }
  }
  order = async (value) => {
    await value.sort((a, b) => {
      return b.commentPraises.length - a.commentPraises.length
    })
    return value
  }
  praiseProblem = async (problem) => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch (`/api/problems/praise`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      },
      body: JSON.stringify({
        problemId: problem.id,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
    	await this.fetchProblem()
    	trackEvent({
    		category: 'Activation',
    		action: 'Praised a problem',
    		label: this.props.user.username,
    		nonInteraction: false,
    	})
    } else {
    	console.log(error)
    }
  }
  submit = async () => {
  	this.setState({
  		error: null,
  		submitting: true,
  	})
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch (`/api/comments`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      },
      body: JSON.stringify({
        problemId: this.state.problem.id,
        comment: this.state.comment,
        parentId: this.state.parentId,
      })
    })
    const { auth, commentId, error } = await res.json()
    if(auth) {
    	trackEvent({
    		category: 'Activation',
    		action: 'Wrote a solution',
    		label: this.props.user.email,
    		nonInteraction: false,
    	})
    	this.setState({
    		success: true,
    		permaId: commentId
    	})
    	setTimeout(() => {
    		this.fetchComments()
    		this.setState({
    			error,
    			submitting: false,
    			comment: '',
    		})
    	}, 1500)
    } else {
      this.setState({
        error,
        submitting: false,
      })
    }
  }
  createMarkup = (content) => {
    const html = marked(content)
    return { __html: html }
  }
  showBlurredSolution = () => {
  	this.setState({
  		showBlurredSolution: true,
  	})
  }
	render() {
		if(this.state.loading) {
			return (
				<div>
					<Helmet>
            <title>Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<ProblemLoader />
				</div>
			)
		}
		if(!this.state.problem) {
			return (
				<div className = 'profile-not-found'>
					<Helmet>
            <title>Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
					<h1>{this.state.error}</h1>
					{
						this.props.user ? (
							<NavLink to = {`/problems`}>
								<button>View problems</button>
							</NavLink>
						) : (
							<NavLink to = {`/signup`}>
								<button>Sign up</button>
							</NavLink>
						)
					}
				</div>
			)
		}
		return (
			<div className = 'problem-wrapper'>
				<Helmet>
          <title>{this.state.problem?.title} - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<div className = 'problem'>
					<div className = 'problem-container'>
						<div className = 'problem-section'>
							<ProblemCard
								location = 'problem'
								problem = {this.state.problem}
								praiseProblem = {this.praiseProblem}
							/>
							<div className = 'problem-markup' dangerouslySetInnerHTML = {this.createMarkup(this.state.problem.problem)} />
						</div>
					</div>
				</div>

				{
					this.props.user && this.props.user.nextPremiumProblem > this.state.problem.problemNumber ? (
						<div className = 'composer'>
		          <textarea
		            className = 'composer-textarea'
		            placeholder = 'How would you answer this interview question?'
		            rows = {5}
		            value = {this.state.comment}
		            onChange = {(ev) => this.setState({ comment: ev.target.value })}
		          />
		          <p className = 'error-text'>{this.state.error}</p>
		          {
		            this.state.submitting ? (
		              <button className = 'composer-btn disabled-button' disabled>Submitting...</button>
		            ) : <button className = 'composer-btn' onClick = {() => this.submit()}>Submit</button>
		          }
		          {
		          	this.state.comments ? (
		          		this.state.comments.map(comment => {
		          			return (
		          				<CommentCard
		          					comment = {comment}
		          					problem = {this.state.problem}
		          					fetchComments = {this.fetchComments}
		          					user = {this.props.user}
		          					permaId = {this.state.permaId}
		          					toPermalink = {this.toPermalink}
		          				/>
		          			)
		          		})
		          	) : null
		          }
		        </div>
					) : this.state.problem.problemNumber <= 5 && !this.props.user ? (
						<div className = 'no-access'>
        			{
        				this.state.showBlurredSolution ? (
        					<NavLink to = {`/signup`} className = 'blurred-section'>
        						<button className = 'aligned-cta'><IosUnlock color = {`#fff`} /><span> Sign up to access this solution</span></button>
	        					<img
			        				src = {BlurredSolution}
			        				alt = 'Blurred solution for Daily Product Prep'
			        				className = 'blurred-solution'
			        			/>
			        			<button className = 'aligned-cta'><IosUnlock color = {`#fff`} /><span> Sign up to access this solution</span></button>
		        			</NavLink>
        				) : (
        					<button className = 'aligned-cta' onClick = {() => this.showBlurredSolution()}><MdListBox color = {`#fff`} />Show solutions</button>
        				)
        			}
        		</div>
					) : (
        		<div className = 'no-access'>
        			{
        				this.state.showBlurredSolution ? (
        					<NavLink to = {`/pricing`} className = 'blurred-section'>
        						<button className = 'aligned-cta'><IosUnlock color = {`#fff`} /><span> Upgrade to Premium for detailed explanations</span></button>
	        					<img
			        				src = {BlurredSolution}
			        				alt = 'Blurred solution for Daily Product Prep'
			        				className = 'blurred-solution'
			        			/>
			        			<button className = 'aligned-cta'><IosUnlock color = {`#fff`} /><span> Upgrade to Premium for detailed explanations</span></button>
		        			</NavLink>
        				) : (
        					<button className = 'aligned-cta' onClick = {() => this.showBlurredSolution()}><MdListBox color = {`#fff`} />Show solutions</button>
        				)
        			}
        		</div>
        	)
				}
			</div>
		)
	}
}