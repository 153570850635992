import React, { PureComponent } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'

import { STORAGE_KEY } from '@/lib/constants'
import { initializeAdTracking, trackEvent } from '@/lib/analytics'
import cookieManager from '@/lib/cookieManager'

import Ribbon from '@/components/Ribbon'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Loader from '@/components/Loader'

import Homepage from '@/pages/Homepage'
import Problems from '@/pages/Problems'
import Problem from '@/pages/Problem'
import Pricing from '@/pages/Pricing'
import Checkout from '@/pages/Checkout'
import Blog from '@/pages/Blog'
import BlogPost from '@/pages/BlogPost'
import LogIn from '@/pages/LogIn'
import SignUp from '@/pages/SignUp'
import Verification from '@/pages/Verification'
import SetPassword from '@/pages/SetPassword'
import Logout from '@/pages/Logout'
import OnboardingComplete from '@/pages/OnboardingComplete'
import Profile from '@/pages/Profile'
import ProfileSettings from '@/pages/ProfileSettings'
import SecuritySettings from '@/pages/SecuritySettings'
import BillingSettings from '@/pages/BillingSettings'
import EmailPreferencesSettings from '@/pages/EmailPreferencesSettings'
import Unsubscribe from '@/pages/Unsubscribe'
import Cancel from '@/pages/Cancel'
import TermsAndConditions from '@/pages/TermsAndConditions'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import E404 from '@/pages/E404'

import '@/_base.scss'

export default class App extends PureComponent {
  state = {
    user: null,
    coupon: null,
    requiresOnboarding: false,
    loading: true,
  }
  componentDidMount = async () => {
    await initializeAdTracking()
    await this.fetchUser()
    await this.setCookies()
    const referrer = await cookieManager.getReferrer()
    if(referrer) {
      await this.checkCouponCode(referrer)
    }
    this.setState({
      loading: false
    })
  }
  setCookies = async () => {
    let parsed = queryString.parse(window.location.search)
    await cookieManager.setCookies(this.state.user, parsed)

    // Activate sale
    if(parsed.ref === 'blackfriday2022') {
      await cookieManager.setCookies(this.state.user, parsed)
    } else if (this.state.user && this.state.user.plan === 'monthly' && this.state.user.type === 'premium') {
      parsed.ref = 'blackfriday2022'
      await cookieManager.setCookies(this.state.user, parsed)
    } else {
      parsed.ref = 'blackfriday2022'
      await cookieManager.setCookies(this.state.user, parsed)
    }
  }
  fetchUser = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/users/auth`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { auth, user, error } = await res.json()
    if(auth) {
      const requiresOnboarding = await this.checkRequiresOnboarding(user)
      await this.setState({
        user,
        requiresOnboarding,
        error,
      })
    } else {
      await localStorage.removeItem(STORAGE_KEY)
      this.setState({
        user,
        error
      })
    }
  }
  checkCouponCode = async (code) => {
    const res = await fetch(`/api/subscriptions/coupons/${code}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      }
    })
    const { auth, coupon } = await res.json()
    if(auth) {
      this.setState({
        coupon,
      })
    }
  }
  checkRequiresOnboarding = (user) => {
    if(!user || (user && user.firstName && user.lastName && user.username && user.password && user.about && user.about.status && user.emailPreferences && user.emailPreferences.problemFrequency)) {
      return false
    } else {
      return true
    }
  }
  login = async (token) => {
    await localStorage.setItem(STORAGE_KEY, token)
    await this.fetchUser()
    // fires after we get the user
    await trackEvent({
      category: 'Retention',
      action: 'User logged in',
      label: this.state.user.email,
      nonInteraction: false,
    })
  }
  logout = async () => {
    // fires before we clear the user
    await trackEvent({
      category: 'Retention',
      action: 'User logged out',
      label: this.state.user.email,
      nonInteraction: false,
    })
    await localStorage.removeItem(STORAGE_KEY)
    await this.fetchUser()
  }
  GuestRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render = {props =>
          this.state.user ? (
            <Redirect
              to = {{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          ) : <Component {...props} {...rest}/>
        }
      />
    )
  }
  FlexRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render = {props =>
          this.state.requiresOnboarding ? (
            <Redirect
              to = {{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          ) : <Component {...props} {...rest}/>
        }
      />
    )
  }
  render() {
    if(this.state.loading) {
      return (
        <div className = 'center-loader'>
          <Helmet>
            <title>Interview Prep for Product Jobs - Daily Product Prep</title>
            <meta
              name = 'description'
              content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
            />
            <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
          </Helmet>
          <Loader />
        </div>
      )
    }
    return (
      <BrowserRouter>
        <Helmet>
          <title>Interview Prep for Product Jobs - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Ace your product interview with daily product prep questions sent to your inbox. Material is prepared by product managers who received offers from Facebook, Google, Amazon, and much more. Product prep questions specifically asked at big companies like Twitter, Yelp, Asana, and more top product tech companies."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <div className = 'app'>
          <Ribbon user = {this.state.user} coupon = {this.state.coupon} />
          <Header user = {this.state.user} />
          <Switch>
            <Route exact path = '/'
              render = {(props) => (
                <Homepage {...props} user = {this.state.user} fetchUser = {this.fetchUser} requiresOnboarding = {this.state.requiresOnboarding} />
              )}
            />
            <Route exact path = '/pricing'
              render = {(props) => (
                <Pricing {...props} user = {this.state.user} coupon = {this.state.coupon} fetchUser = {this.fetchUser} />
              )}
            />
            <Route exact path = '/upgrade'
              render = {(props) => (
                <Pricing {...props} user = {this.state.user} coupon = {this.state.coupon} fetchUser = {this.fetchUser} />
              )}
            />
            <Route exact path = '/secure/checkout'
              render = {(props) => (
                <Checkout {...props} user = {this.state.user} login = {this.login} />
              )}
            />
            <Route exact path = '/blog'
              render = {(props) => (
                <Blog {...props} user = {this.state.user} />
              )}
            />
            <Route exact path = '/blog/:slug'
              render = {(props) => (
                <BlogPost {...props} user = {this.state.user} />
              )}
            />
            <Route exact path = '/setup/complete'
              render = {(props) => (
                <OnboardingComplete {...props} user = {this.state.user} fetchUser = {this.fetchUser} />
              )}
            />
            <this.GuestRoute exact path = '/login' component = {LogIn}
              user = {this.state.user} login = {this.login}
            />
            <this.GuestRoute exact path = '/signup' component = {SignUp}
              user = {this.state.user} login = {this.login}
            />
            <this.GuestRoute exact path = '/verification' component = {Verification}
              login = {this.login}
            />
            <this.GuestRoute exact path = '/set-password' component = {SetPassword} />
            <Route exact path = '/logout'
              render = {(props) => (
                <Logout {...props} logout = {this.logout} />
              )}
            />
            <this.FlexRoute exact path = '/problems' component = {Problems}
              user = {this.state.user} login = {this.login}
            />
            <this.FlexRoute exact path = '/dashboard' component = {Problems}
              user = {this.state.user} login = {this.login}
            />
            <this.FlexRoute exact path = '/problems/:slug' component = {Problem}
              user = {this.state.user}
            />
            <this.FlexRoute exact path = '/problems/:slug/:permaId' component = {Problem}
              user = {this.state.user}
            />
            <Route exact path = '/@/:username'
              render = {(props) => (
                <Profile {...props} user = {this.state.user} />
              )}
            />
            <Route exact path = '/settings/profile'
              render = {(props) => (
                <ProfileSettings {...props} user = {this.state.user} fetchUser = {this.fetchUser} />
              )}
            />
            <Route exact path = '/settings/security'
              render = {(props) => (
                <SecuritySettings {...props} user = {this.state.user} />
              )}
            />
            <Route exact path = '/settings/billing'
              render = {(props) => (
                <BillingSettings {...props} user = {this.state.user} />
              )}
            />
            <Route exact path = '/settings/email-preferences'
              render = {(props) => (
                <EmailPreferencesSettings {...props} user = {this.state.user} fetchUser = {this.fetchUser} />
              )}
            />
            <Route exact path = '/cancel'
              render = {(props) => (
                <Cancel {...props} user = {this.state.user} fetchUser = {this.fetchUser} />
              )}
            />
            <Route exact path = '/unsubscribe'
              render = {(props) => (
                <Unsubscribe {...props} fetchUser = {this.fetchUser} />
              )}
            />
            <Route exact path = '/terms-and-conditions' component = {TermsAndConditions} />
            <Route exact path = '/privacy-policy' component = {PrivacyPolicy} />
            <Route component = {E404} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    )
  }
}
