import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { STORAGE_KEY } from '@/lib/constants'
import cookieManager from '@/lib/cookieManager'
import { trackPageView, trackEvent } from '@/lib/analytics'

import Loader from '@/components/Loader'

import HoldUp from '@/assets/images/hold-up.gif'
import DontStop from '@/assets/images/dont-stop.gif'
import CheckmarkWhite from '@/assets/iconography/checkmarkWhite.svg'

export default class Cancel extends PureComponent {
	state = {
		fetching: true,
		user: null,
    error: null,
    success: false,
    updating: false,
    downgrade: false,
    processingRetention: false,
    code: `retention-on-cancel-aug-2020`
	}
	componentDidMount = async () => {
		trackPageView(window.location.pathname + window.location.search)
		await this.fetchUserWithRedemptions()
		this.setState({
			fetching: false
		})
	}
	fetchUserWithRedemptions = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/users/me/redemptions`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { auth, user, error } = await res.json()
    if(auth) {
      this.setState({
        user,
        error,
      })
    } else {
      this.setState({
        user: null,
        error,
      })
    }
  }
  keepSubscription = async () => {
		this.setState({
			updating: true,
		})
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/subscriptions/keep`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { auth, error } = await res.json()
    if(auth) {
    	this.setState({
        success: true,
        updating: false,
        error,
      })
      setTimeout(() => {
    		this.props.history.push('/')
    	}, 1500)
    } else {
      this.setState({
      	updating: false,
        error,
      })
    }
	}
	retainUserWithOffer = async () => {
		this.setState({
			updating: true,
		})
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/subscriptions/retainUserWithOffer`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        code: this.state.code
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
    	const referrer = await cookieManager.getReferrer()
    	trackEvent({
        category: 'Revenue',
        action: `Retained ${this.props.user.plan} plan via offer`,
        label: referrer,
        nonInteraction: false,
      })
    	this.setState({
        success: true,
        updating: false,
        error,
      })
      setTimeout(() => {
    		this.props.history.push('/')
    	}, 1500)
    } else {
      this.setState({
      	updating: false,
        error,
      })
    }
	}
	cancel = async () => {
		this.setState({
			updating: true,
		})
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/subscriptions/cancel`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { auth, error } = await res.json()
    if(auth) {
    	const referrer = await cookieManager.getReferrer()
    	trackEvent({
        category: 'Revenue',
        action: `Canceled ${this.props.user.plan} plan`,
        label: referrer,
        nonInteraction: false,
      })
    	this.setState({
        success: true,
        updating: false,
        error,
      })
      setTimeout(() => {
    		this.props.history.push('/settings/billing')
    	}, 1500)
    } else {
      this.setState({
      	updating: false,
        error,
      })
    }
	}
	render() {
		if(this.state.fetching) {
			return (
				<div className = 'auth-title'>
					<Helmet>
	          <title>Daily Product Prep</title>
	          <meta
	            name = 'description'
	            content="Land a product management job from Tesla, Uber, Lyft, and other tech companies. Improve your product management interview skills with Daily Product Prep."
	          />
	          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:type" content="image/png" />
	          <meta property="og:image:width" content="1200" />
	          <meta property="og:image:height" content="630" />
	          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
	        </Helmet>
					<Loader />
				</div>
			)
		}
		if(!this.state.user) {
			return (
				<div className = 'profile-not-found padding'>
					<Helmet>
	          <title>Daily Product Prep</title>
	          <meta
	            name = 'description'
	            content="Land a product management job from Tesla, Uber, Lyft, and other tech companies. Improve your product management interview skills with Daily Product Prep."
	          />
	          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:type" content="image/png" />
	          <meta property="og:image:width" content="1200" />
	          <meta property="og:image:height" content="630" />
	          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
	        </Helmet>
					<h1>You're not logged in.</h1>
					<NavLink to = {`/login`}>
						<button>Login</button>
					</NavLink>
				</div>
			)
		}
		if(this.state.user && this.state.user.type === 'free') {
			return (
				<div className = 'profile-not-found padding'>
					<Helmet>
	          <title>Daily Product Prep</title>
	          <meta
	            name = 'description'
	            content="Land a product management job from Tesla, Uber, Lyft, and other tech companies. Improve your product management interview skills with Daily Product Prep."
	          />
	          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:type" content="image/png" />
	          <meta property="og:image:width" content="1200" />
	          <meta property="og:image:height" content="630" />
	          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
	        </Helmet>
					<h1>You're not on a premium account.</h1>
					<NavLink to = {`/pricing`}>
						<button>Upgrade</button>
					</NavLink>
				</div>
			)
		}
		if(this.state.user && this.state.user.subscription && this.state.user.subscription.cancel_at_period_end) {
      return (
      	<div className = 'profile-not-found padding'>
      		<Helmet>
	          <title>Daily Product Prep</title>
	          <meta
	            name = 'description'
	            content="Land a product management job from Tesla, Uber, Lyft, and other tech companies. Improve your product management interview skills with Daily Product Prep."
	          />
	          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:type" content="image/png" />
	          <meta property="og:image:width" content="1200" />
	          <meta property="og:image:height" content="630" />
	          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
	        </Helmet>
					<h1>You've already canceled your account.</h1>
					<p>Your account is set to be canceled at the end of your current billing cycle. Would you like to keep your subscription instead?</p>
					<div className = 'settings-input-item align-items-center'>
						<span className = 'error-text'>{this.state.error}</span>
						{
							this.state.success ? (
								<button className = 'settings-success' disabled>
	        				<img
	        					src = {CheckmarkWhite}
	        					alt = 'Daily Product Prep'
	        				/>
	        				<span>Saved</span>
	        			</button>
							) : this.state.updating ? (
								<button disabled className = 'disabled-button'>Updating...</button>
							) : <button onClick = {() => this.keepSubscription()}>Keep subscription</button>
						}
					</div>
				</div>
      )
    }
    if(this.state.user && this.state.user.redemptions && this.state.user.redemptions.findIndex(redemption => redemption.code === `${this.state.code}-${this.state.user.plan}`) >= 0) {
    	return (
				<div className = 'onboarding'>
					<Helmet>
	          <title>Daily Product Prep</title>
	          <meta
	            name = 'description'
	            content="Land a product management job from Tesla, Uber, Lyft, and other tech companies. Improve your product management interview skills with Daily Product Prep."
	          />
	          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
	          <meta property="og:image:type" content="image/png" />
	          <meta property="og:image:width" content="1200" />
	          <meta property="og:image:height" content="630" />
	          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
	        </Helmet>
					<div className = 'column align-items-center'>
						<img
							src = {DontStop}
							alt = 'Daily Product Prep keep subscription'
							className = 'margin-bottom'
						/>
						<h1 className = 'no-margin'>Stay and find a better job!</h1>
						<p>Wait! Keep improving at product manager interviews with daily product management quesitons.</p>
						<div className = 'settings-input-item align-items-center'>
							<span className = 'error-text'>{this.state.error}</span>
							{
								this.state.success ? (
									<button className = 'settings-success' disabled>
		        				<img
		        					src = {CheckmarkWhite}
		        					alt = 'Daily Product Prep'
		        				/>
		        				<span>Updated</span>
		        			</button>
								) : this.state.updating ? (
									<button disabled className = 'disabled-button'>Updating...</button>
								) : (
									<div className = 'align-items-center text-align-center'>
										<NavLink to = {`/`}>
											<button>Stay, I want to get a better job.</button>
										</NavLink>
										<p className = 'margin-top hover underline xsmall' onClick = {() => this.cancel()}>No thanks, cancel plan.</p>
									</div>
								)
							}
						</div>
					</div>
				</div>
			)
    }
    return (
			<div className = 'onboarding'>
				<Helmet>
          <title>Daily Product Prep</title>
          <meta
            name = 'description'
            content="Land a product management job from Tesla, Uber, Lyft, and other tech companies. Improve your product management interview skills with Daily Product Prep."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
				<div className = 'column align-items-center'>
					<img
						src = {HoldUp}
						alt = 'Daily Product Prep keep subscription'
						className = 'margin-bottom'
					/>
					<h1 className = 'no-margin'>Stay and save 33% off your next {this.state.user.plan === 'monthly' ? 'month' : 'year'}!</h1>
					<p>Wait! If you keep your subscription, we'll shave 33% off your next {this.state.user.plan} charge.</p>
					<div className = 'settings-input-item align-items-center'>
						<span className = 'error-text'>{this.state.error}</span>
						{
							this.state.success ? (
								<button className = 'settings-success' disabled>
	        				<img
	        					src = {CheckmarkWhite}
	        					alt = 'Daily Product Prep'
	        				/>
	        				<span>Updated</span>
	        			</button>
							) : this.state.updating ? (
								<button disabled className = 'disabled-button'>Updating...</button>
							) : (
								<div className = 'align-items-center text-align-center'>
									<button onClick = {() => this.retainUserWithOffer()}>Stay and save 33% off.</button>
									<p className = 'margin-top hover underline xsmall' onClick = {() => this.cancel()}>No thanks, cancel plan.</p>
								</div>
							)
						}
					</div>
				</div>
			</div>
		)
	}
}