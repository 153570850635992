import React, { PureComponent } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import Amazon from '@/assets/companies/amazon.svg'
import Apple from '@/assets/companies/apple.svg'
import Duolingo from '@/assets/companies/duolingo.svg'
import Facebook from '@/assets/companies/facebook.svg'
import Google from '@/assets/companies/google.svg'
import Lyft from '@/assets/companies/lyft.svg'
import Microsoft from '@/assets/companies/microsoft.svg'
import Uber from '@/assets/companies/uber.svg'
import Zendesk from '@/assets/companies/zendesk.svg'
import './styles.scss'

class Hero extends PureComponent {
	state = {
		email: null
	}
	render() {
		return (
			<div className = 'hero'>
				<div className = 'hero-container'>
					<div className = 'hero-copy'>
						<h1>Land a product job.</h1>
						<p>We've helped 10,000+ product managers improve at interviews.</p>
						<div className = 'hero-cta'>
							<input
								type = 'email'
								placeholder = 'Enter your email...'
								onChange = {(ev) => this.setState({ email: ev.target.value })}
								className = 'hero-input'
							/>
							<NavLink to = {{pathname: `/signup`, email: this.state.email}}>
								<button>Get started<span> — it's free</span></button>
							</NavLink>
						</div>
					</div>
					<div className = 'hero-logos'>
						<h3>We help product managers land jobs at top tech companies like:</h3>
						<div className = 'logos'>
							<img
								src = {Amazon}
								alt = 'Daily Product Prep company Amazon'
								className = 'amazon'
							/>
							<img
								src = {Apple}
								alt = 'Daily Product Prep company Apple'
								className = 'apple'
							/>
							<img
								src = {Duolingo}
								alt = 'Daily Product Prep company Duolingo'
								className = 'duolingo'
							/>
							<img
								src = {Facebook}
								alt = 'Daily Product Prep company Facebook'
								className = 'facebook'
							/>
							<img
								src = {Google}
								alt = 'Daily Product Prep company Google'
								className = 'google'
							/>
							<img
								src = {Lyft}
								alt = 'Daily Product Prep company Lyft'
								className = 'lyft'
							/>
							<img
								src = {Microsoft}
								alt = 'Daily Product Prep company Micorsoft'
								className = 'microsoft'
							/>
							<img
								src = {Uber}
								alt = 'Daily Product Prep company Uber'
								className = 'uber'
							/>
							<img
								src = {Zendesk}
								alt = 'Daily Product Prep company Zendesk'
								className = 'zendesk'
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Hero)