import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import './styles.scss'

export default class BlogCTA extends PureComponent {
	render() {
		return (
			<NavLink to = {`/signup`} className = 'blog-cta'>
				<div className = 'blog-cta-container'>
					<h1 className = 'blog-cta-header'>Ace your product interview</h1>
					<h2 className = 'blog-cta-subheader'>Start thinking like a product manager by solving one problem every day.</h2>
					<button className = 'blog-cta-button'>Get started<span className = 'thin'> — it's free</span></button>
				</div>
			</NavLink>
		)
	}
}