import Cookies from 'js-cookie'
const { v4: uuidv4 } = require('uuid')

class CookieManager {
	setCookies(user, parsed) {
		// Visitor ID
		const id = Cookies.get('visitorId')
		if(user && user.id) {
			Cookies.set('visitorId', user.id, { expires: 7 })
		} else if(!id) {
			Cookies.set('visitorId', uuidv4(), { expires: 7 })
		}
		// Referrer
		if(parsed.ref && parsed.ref !== '') {
			Cookies.set('referrer', parsed.ref, { expires: 7 })
		}
	}
	getVisitorId() {
		return Cookies.get('visitorId')
	}
  getReferrer() {
		return Cookies.get('referrer')
  }
}

export default new CookieManager()

