import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import marked from 'marked'

import { trackPageView } from '@/lib/analytics'

import PostLoader from '@/components/PostLoader'
import BlogCTA from '@/components/BlogCTA'

import './styles.scss'

export default class BlogPost extends PureComponent {
  state = {
    post: null,
    loading: true,
  }
  componentDidMount = async () => {
    trackPageView(window.location.pathname + window.location.search)
    await this.fetchPost()
    this.setState({
      loading: false,
    })
  }
  fetchPost = async () => {
    const slug = this.props.match.params.slug
    const res = await fetch(`/api/posts/${slug}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      },
    })
    const { auth, post, error } = await res.json()
    if(auth) {
      this.setState({
        post,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  createMarkup = (content) => {
    const html = marked(content)
    return { __html: html }
  }
  render() {
    if(this.state.loading) {
      return <PostLoader />
    }
    return (
      <div className = 'post'>
        <Helmet>
          <title>{this.state.post.title}</title>
          <meta
            name = 'description'
            content="Learn how to get a product management offer and ace all your product interviews! Be prepared for product interviews from companies like Google, Facebook, Tesla, and more."
          />
          <meta property="og:image" content={this.state.post.featureImage} />
          <meta property="og:image:secure_url" content={this.state.post.featureImage} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="450" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
        <div className = 'post-container'>
          <div className = 'post-content'>
            <h2 className = 'post-title'>{this.state.post.title}</h2>
            <img
              src = {this.state.post.featureImage}
              alt = 'Daily Product Prep'
            />
            <div className = 'post' dangerouslySetInnerHTML = {this.createMarkup(this.state.post.post)} />
            <div className = 'post'>
              <p><NavLink to = {`/signup`}>Daily Product Prep</NavLink> is the #1 resource for Product Manager Interview Questions. With over 250+ PM interview questions & answers sourced from Facebook, Google, Amazon, Airbnb, Yelp, and more, DPP teaches you the product frameworks to ace your next product manager interview. <NavLink to = {`/signup`}>Sign up today!</NavLink></p>
            </div>
          </div>
          <div className = 'post-cta'>
            <BlogCTA />
          </div>
        </div>
      </div>
    )
  }
}
