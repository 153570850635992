import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

import { STORAGE_KEY } from '@/lib/constants'
import cookieManager from '@/lib/cookieManager'
import { trackPageView, trackEvent } from '@/lib/analytics'

import ProcessingPayment from '@/components/ProcessingPayment'
import PricingPlans from '@/components/PricingPlans'
import FeatureComparisonTable from '@/components/FeatureComparisonTable'
import FAQ from '@/components/FAQ'

import './styles.scss'

export default class Pricing extends PureComponent {
  state = {
    monthlyPrice: 1500,
    yearlyPrice: 12000,
    loading: true
  }
  componentDidMount = async () => {
    trackPageView(window.location.pathname + window.location.search)
    await this.calculatePrices()
    this.setState({
      loading: false
    })
  }
  calculatePrices = async () => {
    let monthlyFinal = this.state.monthlyPrice
    let yearlyFinal = this.state.yearlyPrice
    if(this.props.coupon) {
      if(this.props.coupon.subscriptionType === 'both' || this.props.coupon.subscriptionType === 'monthly') {
        monthlyFinal = ((this.state.monthlyPrice - this.props.coupon.amountOffInCents) * ((100 - this.props.coupon.percentOff)/100)).toFixed(2)
      }
      if (this.props.coupon.subscriptionType === 'both' ||  this.props.coupon.subscriptionType === 'yearly') {
        yearlyFinal = ((this.state.yearlyPrice - this.props.coupon.amountOffInCents) * ((100 - this.props.coupon.percentOff)/100)).toFixed(2)
      }
    }
    this.setState({
      monthlyPrice: monthlyFinal / 100,
      yearlyPrice: yearlyFinal,
    })
  }
  updateSubscription = async (plan) => {
    this.setState({
      updatingSubcription: true,
    })
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/subscriptions/plan`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        plan,
        coupon: this.props.coupon ? this.props.coupon.code : null
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      const referrer = await cookieManager.getReferrer()
      trackEvent({
        category: 'Revenue',
        action: plan === 'yearly' ? 'Upgraded from monthly to yearly' : 'Downgraded from yearly to monthly',
        label: referrer,
        value: plan === 'yearly' ? this.state.yearlyPrice / 100 : null,
        nonInteraction: false,
      })
      await this.props.fetchUser()
      this.setState({
        subscriptionUpdated: true
      })
      setTimeout(() => {
        this.props.history.push('/settings/billing')
      }, 1000)
    } else {
      this.setState({
        error,
        updatingSubcription: false,
      })
    }
  }
  render() {
    if(this.state.loading) {
      return null
    }
    return (
      <div className = 'container column'>
        <Helmet>
          <title>Pricing - Daily Product Prep</title>
          <meta
            name = 'description'
            content="Plans as low as $10/mo help you prep for your product management interviews at top companies like Amazon, Yelp, Netflix, and more."
          />
          <meta property="og:image" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:secure_url" content="%PUBLIC_URL%/ogimage.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Land a product job with daily product management interview question examples from Facebook, Amazon, Google, Yelp, Twitter, Asana, and more." />
        </Helmet>
        <ProcessingPayment
          show = {this.state.updatingSubcription}
          success = {this.state.subscriptionUpdated}
        />
        <div className = 'focus-container'>
          <div className = 'focus-title-section'>
            <h3 className = 'focus-subheader'>Pricing</h3>
            <h1 className = 'focus-title'>Gain an advantage interviewing.</h1>
            <h1 className = 'focus-title'>1,000+ product managers placed.</h1>
          </div>
          <div className = 'column'>
            <PricingPlans
              user = {this.props.user}
              coupon = {this.props.coupon}
              monthlyPrice = {this.state.monthlyPrice}
              yearlyPrice = {this.state.yearlyPrice}
              updateSubscription = {this.updateSubscription}
            />
            <FeatureComparisonTable />
            <FAQ />
          </div>
        </div>
      </div>
    )
  }
}
