import React, { PureComponent } from 'react'

import ChevronDown from '@/assets/iconography/chevronDown.svg'

export default class ProblemsFilterRow extends PureComponent {
	render() {
		return (
			<div className = 'problems-filter-row'>
				<div className = 'problems-filter'>
					<div className = 'filter-dropdown'>
						<button onClick = {() => this.props.showOrderDropdown()} className = {this.props.order !== 'Popularity' ? 'filter-selected' : 'popularity-default-button'}>
							{this.props.order}
							<img
								src = {ChevronDown}
								alt = 'Daily Product Prep'
							/>
						</button>
						<div className = {this.props.showOrder ? 'dropdown-content show' : 'dropdown-content'}>
							<button onClick = {() => this.props.setOrder('Popularity')}>Popularity (default)</button>
							<button onClick = {() => this.props.setOrder('Most solutions')}>Most solutions</button>
							<button onClick = {() => this.props.setOrder('Most activity')}>Most activity</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}